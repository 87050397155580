import { CustomIcons, colorSchemes } from '../../../../enums';
import {
  PaymentMethodAction,
  PaymentMethodType
} from '../../../../api/models/wallet';
import cn from 'classnames';
import { TG } from '../../../../helpers/telegram';
import styles from './PaymentMethodCard.module.scss';
import { useAppDispatch } from '../../../../hooks/redux';
import {
  deletePaymentMethodFromWallet,
  setAsPriamry
} from '../../../../store/thunk/walletThunk';
import CustomIcon from '../../../../components/icons/custom';
import { Menu, Button } from '../../../../mantine';
import { nanoid } from 'nanoid';

interface PaymentMethodCardProps extends PaymentMethodType {
  cardNumber?: boolean;
  hasMethod?: boolean;
}
type PaymentMethodCardMenuProps = Pick<PaymentMethodType, 'id' | 'actions'>;

interface MenuItemProps {
  id: number | null;

  action: PaymentMethodAction;
}

const MenuItem = ({ action, id }: MenuItemProps) => {
  const dispatch = useAppDispatch();
  // const handleEdit = () => {};

  // const handleView = () => {};

  const handleDelete = (id: number | null) => {
    if (id) {
      dispatch(deletePaymentMethodFromWallet({ id }));
    }
  };
  const handleSetPrimary = (id: number | null) => {
    if (id) {
      dispatch(setAsPriamry({ id }));
    }
  };

  switch (action) {
    case 'delete':
      return (
        <Menu.Item
          onClick={() => handleDelete(id)}
          rightSection={
            <CustomIcon
              icon={CustomIcons.trashBasket}
              color={'var(--tg-theme-button-color)'}
            />
          }>
          Удалить
        </Menu.Item>
      );
    case 'edit':
      return <Menu.Item rightSection={null}>Редактировать</Menu.Item>;
    case 'set-primary':
      return (
        <Menu.Item
          onClick={() => handleSetPrimary(id)}
          rightSection={
            <CustomIcon
              icon={CustomIcons.wallet}
              color={'var(--tg-theme-button-color)'}
            />
          }>
          Сделать основным
        </Menu.Item>
      );
    case 'view':
      return <Menu.Item rightSection={null}>Смотреть</Menu.Item>;
  }
};

const PaymentMethodCardMenu = ({ id, actions }: PaymentMethodCardMenuProps) => {
  return (
    <Menu>
      <Menu.Target>
        <Button
          className={styles.actionsWithPaymentMethod}
          classNames={{
            root: styles.test,
            inner: styles.test,
            label: styles.test,
            section: styles.test
          }}>
          <CustomIcon icon={CustomIcons.dotsOptions} />
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {actions?.map((action) => (
          <MenuItem action={action} id={id} key={nanoid()} />
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export const PaymentMethodCard = ({
  cardNumber,
  hasMethod,
  icon,
  name,
  id,
  actions,
  status_left,
  identifier,
  min_sum
}: PaymentMethodCardProps) => {
  const colorSheme = TG.WebApp?.colorScheme;
  const textColor = colorSheme === colorSchemes.dark ? '#212121' : 'white';
  const backgroundColor =
    colorSheme === colorSchemes.dark ? 'white' : '#1C1C1E';
  return (
    <div className={cn(styles.methodCardWrapper)}>
      <button
        className={cn('btn-payment', 'btn-payment--add', {
          [styles.mainMethod]: status_left === 'Основной метод'
        })}
        style={{
          backgroundColor: backgroundColor
        }}>
        <div className="d-flex align-items-center me-2">
          <img style={{ width: '44px', height: '44px' }} src={icon}></img>
          <div className="d-flex flex-column align-items-start ms-3">
            <div
              className="mb-1 fs-17"
              style={{
                color: textColor
              }}>
              {name}
            </div>
            <div
              className={styles.idintified}
              style={{
                color: textColor
              }}>
              {identifier}
            </div>
            <div className={styles.statusLeft}>{status_left}</div>
          </div>
        </div>
        {/* <ArrowRight /> */}
      </button>
      <PaymentMethodCardMenu id={id} actions={actions} />
    </div>
  );
};
