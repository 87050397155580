import { type SvgIconProps } from './types';

export const CopyIcon = ({ color = '#FF475A', ...rest }: SvgIconProps) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M0.508301 18.8345V7.30469C0.508301 5.56982 1.37158 4.69824 3.08154 4.69824H4.53418V3.1626C4.53418 1.43604 5.39746 0.556152 7.10742 0.556152H10.2866C11.1748 0.556152 11.8721 0.780273 12.4614 1.38623L16.6533 5.64453C17.2759 6.28369 17.4834 6.93115 17.4834 7.95215V14.6924C17.4834 16.4189 16.6201 17.2988 14.9102 17.2988H13.4575V18.8345C13.4575 20.561 12.6025 21.4409 10.8843 21.4409H3.08154C1.36328 21.4409 0.508301 20.5693 0.508301 18.8345ZM12.6689 10.1436C13.333 10.8242 13.4575 11.2891 13.4575 12.3516V15.9624H14.8354C15.6987 15.9624 16.147 15.4976 16.147 14.6675V7.57031H12.2622C11.3076 7.57031 10.8428 7.11377 10.8428 6.15088V1.89258H7.17383C6.31055 1.89258 5.87061 2.36572 5.87061 3.1875V4.69824H6.01172C6.94141 4.69824 7.46436 4.83936 8.09521 5.48682L12.6689 10.1436ZM12.0464 5.95166C12.0464 6.24219 12.1626 6.3667 12.4531 6.3667H15.7983L12.0464 2.54834V5.95166ZM1.84473 18.8096C1.84473 19.6396 2.28467 20.1045 3.13965 20.1045H10.8179C11.6729 20.1045 12.1211 19.6396 12.1211 18.8096V12.418H7.43115C6.40186 12.418 5.87061 11.895 5.87061 10.8491V6.03467H3.14795C2.28467 6.03467 1.84473 6.50781 1.84473 7.32129V18.8096ZM7.58887 11.1646H11.8638L7.12402 6.3418V10.6997C7.12402 11.0317 7.25684 11.1646 7.58887 11.1646Z"
        fill={color}
      />
    </svg>
  );
};
