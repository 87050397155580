import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface BtnBorderedProps {
  className?: string;
  link?: string;
  text?: string;
  callback?: () => void;
}

const BtnBordered = ({
  className = '',
  link,
  text = '',
  callback
}: BtnBorderedProps) => {
  const renderContent = () => (
    <div className="flex-grow-1 d-flex align-items-center justify-content-between">
      {text}
      <FontAwesomeIcon
        icon={faChevronRight}
        style={{ color: 'var(--tg-theme-link-color)' }}
      />
    </div>
  );

  return link ? (
    <Link className={`btn-bordered ${className}`} to={link}>
      {renderContent()}
    </Link>
  ) : (
    <a
      type="button"
      className={`btn-bordered ${className}`}
      onClick={callback}
      role="button"
      aria-pressed="false">
      {renderContent()}
    </a>
  );
};

export default BtnBordered;
