import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import './page-title.scss';
import { Container } from 'react-bootstrap';
import { Icon } from '../../types/state';
import { CustomIcons, IconSource } from '../../enums';
import CustomIcon from '../icons/custom';

interface PageTitleProps {
  title: string;
  subtitle?: string;
  icon?: Icon;
  iconColor?: string;
}

const PageTitle = ({ title, subtitle, icon, iconColor }: PageTitleProps) => {
  return (
    <div className="page-title">
      <Container>
        <div className="page-title__top">
          {icon && (
            <div className="page-title__icon">
              {icon.source === IconSource.fa && (
                <FontAwesomeIcon
                  icon={['fas', icon.name as IconName]}
                  size="3x"
                />
              )}
              {icon.source === IconSource.custom && (
                <CustomIcon icon={icon.name as CustomIcons} color={iconColor} />
              )}
            </div>
          )}
          <div className="page-title__text">{title}</div>
        </div>
        {subtitle && <div className="page-title__subtitle">{subtitle}</div>}
      </Container>
    </div>
  );
};

export default PageTitle;
