import './PaymentMethod.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { PaymentMethodType } from '../../../../api/models/wallet';
import { type paymentMethod } from '../../../../store/slices/appStateSlice';
import Image from 'react-bootstrap/Image';
import { useAppSelector } from '../../../../hooks/redux';

interface PaymentMethodProps extends PaymentMethodType {
  selectPaymentMethod: (method: paymentMethod) => void;
}

export const PaymentMethod = ({
  name,
  min_sum,
  icon,
  id,
  instruction_text,
  pm_type_slug,
  selectPaymentMethod
}: PaymentMethodProps) => {
  const paymentMethod = useAppSelector((state) => state.appState.paymentMethod);
  return (
    <div
      onClick={() =>
        selectPaymentMethod({ name, id, instruction_text, pm_type_slug })
      }
      className={cn('wrapper check-card', {
        'check-card--checked': paymentMethod?.name === name
      })}>
      <Image src={icon} width={40} height={40} />
      <div className="paymentInfo">
        <span className="fs-17">{name}</span>
        <span className="fs-13 tg-text-hint">{min_sum}</span>
      </div>
      <FontAwesomeIcon
        icon="check"
        size="xl"
        color="var(--crimson)"
        className="checkIcon"
      />
    </div>
  );
};
