import { API_PATHS, BASE_URL } from '../const';
import { api } from './api';
import {
  responseAvailablePaymentMethodsSchema,
  responseUserBalanceDetailsDataSchema,
  responsePaymentHistorySchema,
  responseDeletedPaymentMethodSchema,
  responseSetAsPrimaryPaymentMethodSchemz
} from './models/wallet';
export type AddPaymentMethodParams = {
  type: string;
  current_step?: string;
  inputs?: Record<string, any>;
};

export const requestAvailablePaymentMethods = async () => {
  const response = await api.get(
    `${BASE_URL}${API_PATHS.available_payment_methods}`,
    {
      params: {}
    }
  );

  return responseAvailablePaymentMethodsSchema.parse(response.data);
};

export const requestUserBalanceDetails = async () => {
  const response = await api.get(
    `${BASE_URL}${API_PATHS.user_balance_details}`,
    {
      params: {}
    }
  );

  return responseUserBalanceDetailsDataSchema.parse(response.data);
};

export const requestPaymentHistory = async () => {
  const response = await api.get(`${BASE_URL}${API_PATHS.payment_history}`, {
    params: {}
  });
  return responsePaymentHistorySchema.parse(response.data);
};

export const addPaymentMethod = async (params: any) => {
  const response = await api.post(
    `${BASE_URL}${API_PATHS.addPaymentMethods}`,
    params
  );
  return response.data;
};
export const deletePaymentMethod = async (id: number) => {
  const response = await api.get(
    `${BASE_URL}${API_PATHS.deletePaymentMethod(id)}`,
    {
      params: {}
    }
  );
  return responseDeletedPaymentMethodSchema.parse(response.data);
};

export const setAsPrimary = async (id: number) => {
  const response = await api.get(`${BASE_URL}${API_PATHS.setAsPrimary(id)}`, {
    params: {}
  });
  return responseSetAsPrimaryPaymentMethodSchemz.parse(response.data);
};

export const uploadImages = async (formData: FormData) => {
  const response = await api.post(
    `${BASE_URL}${API_PATHS.uploadImages}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return response.data;
};

export const requestPayoutsMulti = async () => {
  const response = await api.post(
    `${BASE_URL}${API_PATHS.requestPayoutsMulti}`,
    {}
  );
  return response.data;
};
// /wallet/payouts - request - multi
