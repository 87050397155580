import React, {ChangeEvent} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {setSearchProjectValue, setShowProjectsNav} from '../../store/slices/appStateSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';

const SearchPanel = () => {
  const dispatch = useAppDispatch();
  const searchProjectValue = useAppSelector(
    (store) => store.appState.searchProjectValue
  );

  const searchProjectInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchProjectValue(event.target.value));
  };

  const searchInputFocusHandler = () => {
    dispatch(setShowProjectsNav(false));
  };

  const searchInputBlurHandler = () => {
    dispatch(setShowProjectsNav(true));
  };

  const searchInputReset = () => {
    dispatch(setSearchProjectValue(''));
  };

  return (
    <div className="projects-list__search">
      <div className="search">
        <FontAwesomeIcon
          icon={faSearch}
          style={{ color: 'gray', marginRight: '6px' }}
        />
        <input
          className="search__input"
          type="text"
          placeholder="Поиск"
          value={searchProjectValue}
          onChange={searchProjectInputHandler}
          onFocus={searchInputFocusHandler}
          onBlur={searchInputBlurHandler}
        />
        {!!searchProjectValue.length && (
          <button
            type="button"
            className="search__reset"
            onClick={searchInputReset}>
            <FontAwesomeIcon
              icon={faTimes}
              size="1x"
              style={{ color: 'red' }}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchPanel;