import { nanoid } from 'nanoid';
import { Container } from 'react-bootstrap';
import { PaymentInfoItem } from '..';

export interface Payment {
  sum: string;
  date: string;
  currency: string;
}
interface PaymentInfoProps {
  index: number;
  date: string;
  monthTotalSum: number;
  payments: Payment[];
}

export const PaymentInfoByMonth = ({
  date,
  payments,
  index,
  monthTotalSum
}: PaymentInfoProps) => {
  const isFirst = index === 0;

  return (
    <div style={{ marginTop: isFirst ? '0' : '40px' }} key={nanoid(4)}>
      <Container>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <span className="fw-600" style={{ fontSize: '17px' }}>
            {`Выплаты за ${date}`}
          </span>
          <span className="fs-17 fw-600">
            {Math.round(monthTotalSum * 100) / 100}&nbsp;{payments[0].currency}
          </span>
        </div>
      </Container>
      <ul className="list-rows list-rows--hardline">
        {payments.map(({ date, currency, sum }) => (
          <PaymentInfoItem
            key={nanoid(4)}
            label={date}
            currency={currency}
            sum={sum}
            date={date}
          />
        ))}
      </ul>
    </div>
  );
};
