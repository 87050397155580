import styles from './CustomError.module.scss';
import { ErrorIcon, WarningIcon } from '../../icons';
export interface CustomErrorProps {
  type: 'error';
  id: string;
  text: string;
  is_warning: boolean;
}

export const CustomError = ({
  id,
  is_warning = true,
  text,
  type
}: CustomErrorProps) => {
  const errorBackColor = '#FFE0DE';
  const warningBackColor = '#FFF8EE';
  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundColor: is_warning ? warningBackColor : errorBackColor
      }}>
      {is_warning ? (
        <WarningIcon className={styles.errorIcon} />
      ) : (
        <ErrorIcon className={styles.errorIcon} />
      )}
      <p className={styles.errorText}>{text}</p>
    </div>
  );
};
