import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ProjectsListItemType } from '../../models/projects/projects';
import './project-item.scss';
import { Container } from 'react-bootstrap';
import { apiKey } from '../../helpers/api';
import { useAppSelector } from '../../hooks/redux';
import { saveScrollPosition } from '../../helpers/scroll';

interface Props {
  project: ProjectsListItemType;
}

const ProjectItem = ({ project }: Props): JSX.Element => {
  const { id, name, logo, categories, offers_count } = project;

  const { selectedCategory } = useAppSelector((state) => state.appState);
  const projectLink = `/project/${id}?key=${apiKey}`;

  const projectClickHandler = useCallback(() => {
    saveScrollPosition();

    setTimeout(() => {
      window.scroll(0, 0);
    });
  }, []);

  return (
    <>
      <li className="project">
        <Container>
          <Link
            className="project__link"
            to={projectLink}
            onClick={projectClickHandler}>
            <img className="project__image" src={logo} alt={name} />
            <div className="project__info">
              <span className="project__name">{name}</span>
              <span className="project__category">
                {selectedCategory
                  ? 'Предложения: ' + offers_count
                  : // их там в мписке может быть несколько, беру просто первое для ui
                    categories[0].name}
              </span>
            </div>
          </Link>
        </Container>
        <div className="line"></div>
      </li>
    </>
  );
};

export default ProjectItem;
