import parse from 'html-react-parser';
import styles from './HtmlText.module.scss';
export interface HtmlTextProps {
  type: 'html_text';
  id: string;
  text: string;
}

export const HtmlText = ({ text, id, type }: HtmlTextProps) => {
  return <div className={styles.htmlText}>{parse(text)}</div>;
};
