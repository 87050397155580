import React, { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { ModalTypes } from '../../enums';
import { TG } from '../../helpers/telegram';
import cn from 'classnames';
import './modal.scss';

interface CustomModalProps {
  children: ReactNode;
  title?: string;
  closeBtn?: boolean;
  className?: string;
  show: boolean;
  type?: ModalTypes;
  centered?: boolean;
  fullscreen?:
    | true
    | string
    | 'sm-down'
    | 'md-down'
    | 'lg-down'
    | 'xl-down'
    | 'xxl-down';
  hideHandler: () => void;
}

const CustomModal = ({
  children,
  title,
  show,
  closeBtn = true,
  className,
  type = ModalTypes.default,
  centered = true,
  hideHandler,
  fullscreen
}: CustomModalProps): JSX.Element => {
  const customModalClassName = cn(
    'custom-modal',
    `custom-modal--${type}`,
    className
  );

  const closeButtonVariant =
    TG.WebApp?.colorScheme === 'light' ? 'dark' : 'white';

  return (
    <Modal
      className={customModalClassName}
      show={show}
      onHide={hideHandler}
      centered={centered}
      fullscreen={fullscreen}>
      <Modal.Header closeButton={closeBtn} closeVariant={closeButtonVariant}>
        {title && <Modal.Title>{title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
