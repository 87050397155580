import { config } from '../config';
import { TG } from './telegram';

const searchParams = new URLSearchParams(window.location.search);

export const apiKey = searchParams.get('key');

// for testing - delete later
// export const apiKey = 'y09Y0momiHQPr5UkdJqkxHu1ovRKEyxw';
// eslint-disable-next-line no-debugger

const chatId = searchParams.get('chat_id');

export const userId =
  config.isTest && chatId
    ? parseInt(chatId) ?? TG.resolveUser()
    : TG.resolveUser();
