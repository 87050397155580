import { Skeleton } from '../../../../mantine';

export const PaymentmethodSkeleton = ({ count = 1 }: { count: number }) => {
  return (
    <>
      {Array.from({ length: count }, (_, index) => (
        <Skeleton w={'100%'} height={'76px'} radius={'16px'} key={index} />
      ))}
    </>
  );
};
