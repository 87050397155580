import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { useEffect, useState } from 'react';
import ErrorPage from './pages/error/error';
import { TG } from './helpers/telegram';
import initFontAwesome from './helpers/fontawesome';
import { colorSchemes } from './enums';
import { apiKey } from './helpers/api';
import { calculateWindowWidth } from './helpers/common';
import { AppRoutes } from './app-routes/app-routes';
initFontAwesome();

const App = () => {
  const [windowWidth, setWindowWidth] = useState(calculateWindowWidth());
  const botApiVersion = TG.getBotApiVersion();
  const appStyle = { width: windowWidth ?? '100%' };
  const appClass = `app scheme-${TG.WebApp?.colorScheme ?? colorSchemes.light}`;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(calculateWindowWidth());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  if (!apiKey) {
    return <ErrorPage alert={'Отсутствует ключ авторизации'} />;
  }

  if (botApiVersion === '6.0') {
    TG.WebApp?.showAlert(
      'Вы используете устаревшую версию Telegram. Для использования всех возможностей бота необходимо обновить приложение'
    );
    return null;
  }

  return (
    <div className={appClass} style={appStyle}>
      {/* <TelegramBackButton /> */}
      {/* <TelegramMainButton /> */}
      <AppRoutes />
    </div>
  );
};

export default App;
