import { z } from 'zod';
import { projectInfoSchema, projectsListSchema } from '../../models/projects/projects';
import {
  landingListSchema,
  promocodesSchema
} from '../../models/projects/promocodes';
import { categoriesSchema } from '../../models/projects/categories';

export const projectsAndCategoriesDataSchema = z.object({
  categories: categoriesSchema,
  projects: projectsListSchema
});

export const responseProjectsAndCategoriesSchema = z.object({
  success: z.boolean(),
  data: projectsAndCategoriesDataSchema
});

export const projectPromosInfoDataSchema = z.object({
  project: projectInfoSchema,
  promocodes: promocodesSchema
});
export const participateInProjectDataSchema = z.object({
  id: z.number(),
  erros: z.array(z.string()).optional().nullable(),
  landings: landingListSchema
});
export const responseProjectPromosInfoSchema = z.object({
  success: z.boolean(),
  data: projectPromosInfoDataSchema
});

export const responseParticipateInProjectSchema = z.object({
  data: participateInProjectDataSchema,
  success: z.boolean()
});

export type ProjectPromosInfoDataType = z.infer<
  typeof projectPromosInfoDataSchema
>;
export type ProjectsAndCategoriesDataType = z.infer<
  typeof projectsAndCategoriesDataSchema
>;
export type ResponseProjectPromosInfoType = z.infer<
  typeof responseProjectPromosInfoSchema
>;
export type ResponseProjectsAndCategoriesType = z.infer<
  typeof responseProjectsAndCategoriesSchema
>;
export type ParticipateInProjectDataType = z.infer<
  typeof participateInProjectDataSchema
>;
export type ReponseParticipateInProjectType = z.infer<
  typeof responseParticipateInProjectSchema
>;

