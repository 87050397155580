import styles from './TextFieldWithSwitcher.module.scss';
import { TextInput, Divider, Text, Switch } from '../../../../mantine';
import { useFormContext } from 'react-hook-form';
import { CommonBlockTypes } from '../../componentsModel';
interface TextField extends CommonBlockTypes {
  type: 'input';
  value: string;
  read_only: boolean;
  required: boolean;
  keyboard_type: string;
  label: string;
}

interface Switcher extends CommonBlockTypes {
  type: 'switch';
  label: string;
  description: string;
  value: boolean;
  required: boolean;
}
export interface TextFieldWithSwitcherProps {
  type: 'textfield_with_switcher';
  id: string;
  textfield: TextField;
  switcher: Switcher;
}
export const TextFieldWithSwitcher = ({
  id,
  switcher,
  textfield,
  type
}: TextFieldWithSwitcherProps) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  return (
    <div className={styles.wrapper}>
      <TextInput
        withAsterisk={textfield.required}
        error={textfield.id in errors || textfield.error}
        classNames={{ input: styles.TextInput }}
        label={textfield.label}
        placeholder={textfield.value}
        {...register(textfield.id, { required: true })}
      />
      <Divider
        color={textfield.id in errors || textfield.error ? 'red' : '#C7C7CC'}
      />
      <div className={styles.switcherWrapper}>
        <Text className={styles.switcherDescription}>
          {switcher.description}
        </Text>
        <Switch
          color={'var(--tg-theme-button-color)'}
          size="md"
          {...register(switcher.id)}
          defaultChecked={switcher.value}
        />
      </div>
      <Text></Text>
    </div>
  );
};
