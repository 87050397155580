import React, { useEffect } from 'react';
import parseHtml from 'html-react-parser';
import { Container } from 'react-bootstrap';
import PageHeader from '../../../components/page-header/page-header';
import { NotFound } from '../../not-found/not-found';
import PageContent from '../../../components/page-content/page-content';
import { TG } from '../../../helpers/telegram';
import { PresentIcon } from '../../../components/icons/present';
import './bonuses.scss';
import { useAppSelector } from '../../../hooks/redux';

const ProjectBonuses = () => {
  const { currentProject } = useAppSelector((state) => state.projects);

  if (!currentProject) {
    return <NotFound />;
  }

  const { bonus_condition, name, allowed_platforms, allowed_regions } =
    currentProject.project;

  useEffect(() => {
    TG.MainButton?.hide();
    window.scroll(0, 0);
  }, []);

  const allowedRegions = allowed_regions.map(
    (item) => `${item.country}: ${item.cities}`
  );

  const platformsList = allowed_platforms.join(', ');

  return (
    <div className="project-bonuses">
      <PageHeader
        title={'Подробнее о бонусе'}
        subtitle={name}
        icon={<PresentIcon color="#ffffff" />}
      />
      <PageContent>
        <Container>
          {bonus_condition && (
            <div className="project-bonuses__bonuses-list mb-4">
              <div className="page__h2">{'Условия'}</div>
              <div className="text-md bonus-conditions">
                {parseHtml(bonus_condition)}
              </div>
            </div>
          )}
          {allowedRegions && allowedRegions.length > 0 && (
            <div className="mb-4">
              <div className="page__h2">{'Доступно в регионах'}</div>
              <div className="text-md">{allowedRegions}</div>
            </div>
          )}
          {allowed_platforms && (
            <div className="mb-4">
              <div className="page__h2">{'Где применяется бонус'}</div>
              <div className="text-md">{platformsList}</div>
            </div>
          )}
        </Container>
      </PageContent>
    </div>
  );
};

export default ProjectBonuses;
