import { type SvgIconProps } from './types';

export const PlusIcon = ({ color = '#FF475A', ...rest }: SvgIconProps) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M0.988281 10.5391C0.988281 10.25 1.08984 10.0039 1.29297 9.80078C1.50391 9.58984 1.75 9.48438 2.03125 9.48438H9.61328V1.91406C9.61328 1.63281 9.71484 1.39062 9.91797 1.1875C10.1211 0.976562 10.3672 0.871094 10.6562 0.871094C10.9453 0.871094 11.1914 0.976562 11.3945 1.1875C11.6055 1.39062 11.7109 1.63281 11.7109 1.91406V9.48438H19.2812C19.5625 9.48438 19.8047 9.58984 20.0078 9.80078C20.2188 10.0039 20.3242 10.25 20.3242 10.5391C20.3242 10.8281 20.2188 11.0742 20.0078 11.2773C19.8047 11.4805 19.5625 11.582 19.2812 11.582H11.7109V19.1641C11.7109 19.4453 11.6055 19.6875 11.3945 19.8906C11.1914 20.1016 10.9453 20.207 10.6562 20.207C10.3672 20.207 10.1211 20.1016 9.91797 19.8906C9.71484 19.6875 9.61328 19.4453 9.61328 19.1641V11.582H2.03125C1.75 11.582 1.50391 11.4805 1.29297 11.2773C1.08984 11.0742 0.988281 10.8281 0.988281 10.5391Z"
        fill={color}
      />
    </svg>
  );
};
