import { useEffect, useState } from 'react';
import { TG } from '../../helpers/telegram';
import './add-payment-method.scss';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { addPaymentMethod } from '../../api/wallet';
import {
  Block,
  MODEL,
  Data,
  isNotUiBlocks,
  RedirectBlock
} from './componentsModel';
import { useForm, FormProvider } from 'react-hook-form';
import { PATHS } from '../../const';
import { useAppSelector } from '../../hooks/redux';
import Loading from '../loading/loading';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';
import ErrorPage from '../error/error';

function isRedirectBlock(block: Block): block is RedirectBlock {
  return (block as RedirectBlock).type === 'redirect';
}

const AddPaymentMethod = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id, step } = useParams();
  const [data, setData] = useState<Data | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const methods = useForm();
  const selecableCardId = useAppSelector(
    (state) => state.wallet.selectedCard.id
  );

  useEffect(() => {
    const handleClick = () => {
      navigate(PATHS.wallet);
    };
    TG.BackButton?.onClick(handleClick);
    return () => {
      TG.BackButton?.offClick(handleClick);
    };
  }, []);

  useEffect(() => {
    const redirectBlock = data?.page.find(isRedirectBlock);
    if (redirectBlock) {
      TG.MainButton?.hide();
      TG.BackButton?.hide();

      TG?.OpenLink?.(redirectBlock.url);

      navigate(PATHS.main);
      return;
    }

    const handleClick = (data?: Record<string, any>) => {
      // if (selecableCardId && data) {
      //   data['selectable_card_list'] = selecableCardId;
      // }
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const res = await addPaymentMethod({
            type: id,
            inputs: data,
            current_step: searchParams.get('step')
          });

          setData({
            floating_button: res.floating_button,
            page: res.page,
            step: res.step,
            is_final: Boolean(res?.is_final)
          });
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set('step', res.step);
          setSearchParams(newSearchParams);
          if (res?.is_final) {
            TG.MainButton?.setText('Перейти в кошелек');
          } else {
            TG.MainButton?.setText(res.floating_button.label);
          }
        } catch (err) {
          setError(err);
        } finally {
          setIsLoading(false);
          methods.reset();
        }
      };
      fetchData();
    };

    const handleError = (errors: any) => {
      console.log(errors);
    };

    let handleClickWrapper: () => void;

    if (data?.is_final) {
      handleClickWrapper = () => {
        navigate(PATHS.wallet);
      };
    } else {
      handleClickWrapper = methods.handleSubmit(handleClick, handleError);
    }

    TG.MainButton?.onClick(handleClickWrapper);

    return () => {
      TG.MainButton?.offClick(handleClickWrapper);
    };
  }, [data, selecableCardId]);

  useEffect(() => {
    TG.MainButton?.show();
    return () => {
      TG.MainButton?.hide();
    };
  }, []);

  // при первом заходе мы делаем запрос на первый шаг по id ('test', если тестовый метод)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await addPaymentMethod({
          type: id,
          step: searchParams.get('step')
        });
        setData({
          floating_button: res.floating_button,
          page: res.page,
          step: res.step
        });
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('step', res.step);
        setSearchParams(newSearchParams);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPage />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className="page add-payment-method">
      <form>
        <FormProvider {...methods}>
          {data.page.map((block) =>
            isNotUiBlocks.includes(block.type) ? null : renderBlock(block)
          )}
        </FormProvider>
      </form>
    </div>
  );
};
export default AddPaymentMethod;

export function renderBlock(block: Block): JSX.Element | null {
  switch (block.type) {
    case 'title':
      return <MODEL.title key={block.id} {...block} />;
    case 'html_text':
      return <MODEL.html_text key={block.id} {...block} />;
    case 'input':
      if (block.keyboard_type === 'text') {
        return <MODEL.textInput key={block.id} {...block} />;
      } else if (block.keyboard_type === 'number') {
        return <MODEL.numberInput key={block.id} {...block} />;
      } else {
        return null;
      }
    case 'button':
      return <MODEL.button key={block.id} {...block} />;
    case 'checkbox':
      return <MODEL.checkbox key={block.id} {...block} />;
    case 'copyable_text_list':
      return <MODEL.copyable_text_list key={block.id} {...block} />;
    case 'file':
      return <MODEL.file key={block.id} {...block} />;
    case 'file_collection':
      return <MODEL.file_collection key={block.id} {...block} />;
    case 'pm_card':
      return <MODEL.pm_card key={block.id} {...block} />;
    case 'pm_info':
      return <MODEL.pm_info key={block.id} {...block} />;
    case 'selectable_card_list':
      return <MODEL.selectable_card_list key={block.id} {...block} />;
    case 'switch':
      return <MODEL.switch key={block.id} {...block} />;
    case 'text':
      return <MODEL.text key={block.id} {...block} />;
    case 'textfield_with_switcher':
      return <MODEL.textfield_with_switcher key={block.id} {...block} />;
    case 'emoji':
      return <MODEL.emoji key={block.id} {...block} />;
    case 'error':
      return <MODEL.error key={block.id} {...block} />;
    case 'dropdown':
      return <MODEL.dropdown key={block.id} {...block} />;
    case 'date':
      return <MODEL.date key={block.id} {...block} />;
    default:
      return <div>Произошла ошибка..</div>;
  }
}
