import { useEffect } from 'react';
import { NotFound } from '../../not-found/not-found';
import GroupItem from '../../../components/group/group';
import { Container } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import './offers.scss';
import { getFirstSentence, truncate } from '../../../helpers/common';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/page-header/page-header';
import PageContent from '../../../components/page-content/page-content';
import BtnBordered from '../../../components/buttons/btn-bordered';
import { useAppSelector } from '../../../hooks/redux';
import { LandingListItemType } from '../../../models/projects/promocodes';
import { TG } from '../../../helpers/telegram';
import { apiKey } from '../../../helpers/api';

const ProjectOffers = () => {
  const { currentProject } = useAppSelector((state) => state.projects);

  useEffect(() => {
    TG.MainButton?.hide();
  }, []);

  if (!currentProject) {
    return <NotFound />;
  }

  const {
    id,
    logo,
    name,
    product_info,
    forbidden,
    bonus_condition,
    subscribers_bonuses_objects,
    allowed_regions,
    allowed_platforms,
    goals,
    site,
    has_promocodes
  } = currentProject.project;

  const { landings } = currentProject.promocodes;

  /* replace can_request_promo with landing.can_request_promo or whatever comes from backend */
  // const can_request_promo = false;
  // const isPromoLanding =
  //   landings && landings[0].promos.length > 0 ? true : can_request_promo;

  const allowedRegions = allowed_regions.map(
    (item) => `${item.country}: ${item.cities}`
  );

  const renderProductInfo = () => {
    const firstSentence = getFirstSentence(product_info);
    const maxSymbols = 100;

    if (firstSentence.length > maxSymbols) {
      return truncate(product_info, maxSymbols, true);
    }

    return firstSentence + '.';
  };

  const aboutLink = `/project/${id}/about?key=${apiKey}`;
  const bonusesLink = `/project/${id}/bonuses?key=${apiKey}`;
  const forbiddenLink = `/project/${id}/forbidden?key=${apiKey}`;

  const renderBonusesUse = !!(
    bonus_condition ||
    allowedRegions.length ||
    allowed_platforms.length
  );

  return (
    <div className="project-offers">
      <PageHeader
        title={name}
        icon={<img className="project-offers__logo" src={logo} alt={name} />}
      />
      <PageContent>
        <Container>
          {product_info && (
            <div className="mb-2">
              <p className="project-offers__description">
                {renderProductInfo()}
              </p>
              <Link to={aboutLink}>{'Подробнее'}</Link>
            </div>
          )}

          {/* sub bonuses */}
          {/* {subscribers_bonuses_objects &&
            subscribers_bonuses_objects.length > 0 && (
              <div className="sub-bonuses-wrapper mb-2">
                <div className="page__h2 mb-0">
                  Выгода для тебя и твоих друзей
                </div>
                {subscribers_bonuses_objects.map((sub_bonus) => (
                  <SubBonusItem key={sub_bonus.text} sub_bonus={sub_bonus} />
                ))}
                
              </div>
            )} */}

          {/* goals */}
          {/* {goals && goals.length > 0 && (
            <div className="goals-wrapper mb-4">
              <div className="page__h2 mb-0">Оплата</div>
              {goals.map((goal) => (
                <GoalItem key={nanoid()} goal={goal} />
              ))}
            </div>
          )} */}

          {/* landing groups */}
          {landings && landings.length > 0 && (
            <div>
              <div className="page__h2">
                {has_promocodes ? 'Промокоды' : 'Ссылки'}
              </div>
              <ul className="project-offers__list">
                {landings.map((landingGroup: LandingListItemType) => (
                  <GroupItem
                    projectId={id}
                    projectName={name}
                    landingGroup={landingGroup}
                    goals={goals}
                    key={nanoid()}
                    has_promocodes={has_promocodes}
                  />
                ))}
              </ul>
            </div>
          )}

          {/* additional info */}
          <div>
            <div className="page__h2">Дополнительная информация</div>
            <BtnBordered
              className="mb-3"
              link={forbiddenLink}
              text={'Запрещено'}
            />
            {site && (
              <BtnBordered
                className="mb-3"
                text={'Сайт проекта'}
                callback={() => TG?.OpenLink?.(site)}
              />
            )}
            {renderBonusesUse && (
              <BtnBordered
                className="mb-3"
                link={bonusesLink}
                text={'Где и как получить выгоду'}
              />
            )}
          </div>
        </Container>
      </PageContent>
    </div>
  );
};

export default ProjectOffers;
