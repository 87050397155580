import React from 'react';
import './loader.scss';
        
const Loader = () => {
  return (
    <div className="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
        
export default Loader;
        