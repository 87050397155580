export enum HapticFeedbackTypes {
  error = 'error',
  success = 'success',
  warning = 'warning',
  ok = 'success'
}

export enum ModalTypes {
  default = 'default',
  blind = 'blind'
}

export enum colorSchemes {
  light = 'light',
  dark = 'dark'
}

export enum GrabEvents {
  copy = 'Copy',
  redirect = 'Redirect',
  copy_redirect = 'Copy and redirect',
}

export enum CustomIcons {
  copy = 'copy',
  chat = 'chat',
  binoculars = 'binoculars',
  binoculars_lg = 'binoculars_lg',
  download = 'download',
  gift = 'gift',
  user = 'user',
  user_group = 'user_group',
  user_group_lg = 'user_group_lg',
  screen = 'screen',
  turtle = 'turtle',
  clock = 'clock',
  card = 'card',
  case = 'case',
  horn = 'horn',
  ya_zen = 'ya_zen',
  vk = 'vk',
  ok = 'ok',
  mastercard = 'mastercard',
  wallet = 'wallet',
  dotsOptions = 'dotsOptions',
  trashBasket = 'trashBasket'
}

export enum IconSource { custom = 'custom', fa='fa' }

export enum PaymentMethods {
  self_employed = 'self-employed',
  individual_entrepreneur = 'individual-entrepreneur'
}
