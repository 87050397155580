import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import { NotFound } from '../not-found/not-found';
import { useLoadProjectInfo } from '../../hooks/useLoadProjectInfo';
import Loading from '../loading/loading';
import ErrorPage from '../error/error';
import { PATHS } from '../../const';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

const Project = () => {
  const { currentProject, isLoadingProject, errorProject, errorIdProject } =
    useAppSelector((state) => state.projects);
  const { id } = useParams<string>();
  const loadProjectInfo = useLoadProjectInfo();

  useEffect(() => {
    loadProjectInfo(Number(id));
  }, [id]);

  useTelegramBackButton();

  if (isLoadingProject) {
    return <Loading />;
  }

  if (errorProject) {
    return (
      <ErrorPage
        alert={`server_error:\n${errorProject}\n\n${errorIdProject}: ${errorIdProject}`}
      />
    );
  }

  if (!currentProject) {
    return (
      <NotFound
        alert="Данный проект не найден"
        navigateTo={PATHS.projects as keyof typeof PATHS}
        linkText="Назад к списку проектов"
      />
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default Project;
