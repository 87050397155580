import { useEffect } from 'react';
import './projects.scss';
import { Outlet, useLocation } from 'react-router-dom';
import { PATHS } from '../../const';
import ProjectsNav from './projects-nav';
import { useAppSelector } from '../../hooks/redux';
import { useLoadProjects } from '../../hooks/useLoadProjects';
import cn from 'classnames';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

const Projects = () => {
  const loadProjects = useLoadProjects();
  const { showProjectsNav, searchProjectValue, selectedCategory } =
    useAppSelector((state) => state.appState);
  const { pathname } = useLocation();
  useTelegramBackButton();

  const isShowNav =
    (showProjectsNav && selectedCategory === null && !searchProjectValue) ||
    pathname === PATHS.projects_categories;

  useEffect(() => {
    loadProjects();
  }, []);

  return (
    <div className="page projects">
      {isShowNav && <ProjectsNav />}
      <div
        className={cn({
          ['outletWrapper']: isShowNav
        })}>
        <Outlet />
      </div>
    </div>
  );
};

export default Projects;
