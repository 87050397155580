import { Container } from 'react-bootstrap';
import PageTitle from '../../components/page-title/page-title';
import { nanoid } from 'nanoid';
import './referrals.scss';
import { requestReferralsList } from '../../api/referrals';
import Loading from '../loading/loading';
import ErrorPage from '../error/error';
import { useFetchData } from '../../hooks/useFetchData';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

const Referrals = () => {
  const { data, error, loading } = useFetchData(requestReferralsList);
  useTelegramBackButton();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPage alert={`error:\n${error}`} />;
  }

  if (!data?.referrals) {
    return <h1>Рефералы не найдены</h1>;
  }

  return (
    <div className="page referrals">
      <PageTitle title="Рефералы" />
      <div className="page-content">
        <Container>
          <ul className="list-ios">
            {data.referrals.map((referal) => {
              const { name, reward_total, created_at, status } = referal;
              return (
                <li className="list-ios__item" key={nanoid(4)}>
                  <div className="list-ios__left">
                    <div className="list-ios__name">{name}</div>
                    <div className="list-ios__date">{created_at}</div>
                  </div>
                  <div className="list-ios__right">
                    <div>{reward_total}</div>
                    <div className="list-ios__status">{status}</div>
                  </div>
                </li>
              );
            })}
          </ul>
        </Container>
      </div>
    </div>
  );
};

export default Referrals;
