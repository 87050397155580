import { Dispatch, useEffect } from 'react';
import { ModalTypes, colorSchemes } from '../../enums';
import CustomModal from '../../components/modal/modal';
import { TG } from '../../helpers/telegram';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../const';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  paymentMethod,
  setPaymentMethod
} from '../../store/slices/appStateSlice';
import cn from 'classnames';
import { apiKey } from '../../helpers/api';
import { PaymentInfoConditions, PaymentMethod } from './components';
interface AddPaymentMethodModalProps {
  show: boolean;
  setShow: Dispatch<boolean>;
}

const AddPaymentMethodModal = ({
  show,
  setShow
}: AddPaymentMethodModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedPaymentMethod = useAppSelector(
    (state) => state.appState.paymentMethod
  );
  const availablePaymentMethods = useAppSelector(
    (state) => state.wallet.availablePaymentMethods.data
  );
  const customModalClassName = cn(
    'app',
    `scheme-${TG.WebApp?.colorScheme ?? colorSchemes.light}`
  );

  const handleSelectPaymentMethod = (method: paymentMethod | null) => {
    dispatch(setPaymentMethod(method));
  };
  const handleAddPaymentMethod = () => {
    if (!selectedPaymentMethod) {
      return null;
    } else if (!selectedPaymentMethod.pm_type_slug) {
      return null;
    } else {
      const pm_type_slug = String(selectedPaymentMethod.pm_type_slug);
      // TODO
      // navigate(`${PATHS.addWallet(pm_type_slug)}?key=${apiKey}&step=''`);
      navigate(`${PATHS.addWallet(pm_type_slug)}?key=${apiKey}`);
    }
  };

  useEffect(() => {
    TG.MainButton?.onClick(handleAddPaymentMethod);
    return () => {
      TG.MainButton?.offClick(handleAddPaymentMethod);
    };
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (show) {
      TG.MainButton?.show();
      TG.MainButton?.setText('Добавить');
    }
    return () => {
      TG.MainButton?.hide();
      dispatch(setPaymentMethod(null));
    };
  }, [show]);

  return (
    <CustomModal
      title="Привязать метод выплаты"
      className={customModalClassName}
      show={show}
      hideHandler={() => {
        dispatch(setPaymentMethod(null));
        setShow(false);
      }}
      type={ModalTypes.blind}>
      <div className="choose-payment-method">
        {availablePaymentMethods ? (
          availablePaymentMethods.data.available.map((method) => (
            <PaymentMethod
              key={method.id}
              {...method}
              selectPaymentMethod={handleSelectPaymentMethod}
            />
          ))
        ) : (
          <p>На данный момент нет доступных платежных методов</p>
        )}
      </div>
      <PaymentInfoConditions />
    </CustomModal>
  );
};

export default AddPaymentMethodModal;
