import React, { FC } from 'react';
import { MenuItem } from '../../types/state';
import { Link } from 'react-router-dom';
import SquaredIcon from '../icons/squared/squared-icon';
import { apiKey } from '../../helpers/api';

interface MenuItemProps {
  item: MenuItem;
}

const MenuListItem = ({ item }: MenuItemProps) => {
  const { name, desc, icon, path, callback } = item;

  const itemContent = (
    <>
      <div className="me-2">
        <div className="menu-item__name">{name}</div>
        <div className="menu-item__desc">{desc}</div>
      </div>
      <SquaredIcon icon={{ name: icon.name, source: icon.source }} />
    </>
  );

  return (
    <li className="menu-item">
      {path ? (
        <Link
          className="menu-item__link"
          to={`${path}?key=${apiKey}`}
          state={{ linkFromMainMenu: true }}>
          {itemContent}
        </Link>
      ) : (
        <div
          className="menu-item__link"
          onClick={() => {
            typeof callback === 'function' && callback();
          }}>
          {itemContent}
        </div>
      )}
    </li>
  );
};

export default MenuListItem;
