import { type SvgIconProps } from './types';

export const WarningIcon = ({ color = '#FF9500', ...rest }: SvgIconProps) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M9.5 17.6646C4.75195 17.6646 0.842285 13.7549 0.842285 9.00684C0.842285 4.26709 4.75195 0.349121 9.4917 0.349121C14.2397 0.349121 18.1577 4.26709 18.1577 9.00684C18.1577 13.7549 14.248 17.6646 9.5 17.6646ZM9.5 10.3599C10.0146 10.3599 10.3218 10.0693 10.3384 9.52979L10.4712 5.61182C10.4878 5.05566 10.0811 4.65723 9.4917 4.65723C8.90234 4.65723 8.50391 5.04736 8.52881 5.60352L8.65332 9.52979C8.66992 10.0693 8.97705 10.3599 9.5 10.3599ZM9.5 13.2817C10.106 13.2817 10.5874 12.875 10.5874 12.2856C10.5874 11.7046 10.106 11.2896 9.5 11.2896C8.89404 11.2896 8.4209 11.7046 8.4209 12.2856C8.4209 12.8667 8.90234 13.2817 9.5 13.2817Z"
        fill={color}
      />
    </svg>
  );
};
