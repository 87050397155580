import { type SvgIconProps } from './types';

export const ArrowRight = ({ color = '#AEAEB2', ...rest }: SvgIconProps) => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M8.29736 8.00684C8.29736 8.21436 8.21436 8.39697 8.05664 8.55469L1.48242 14.9878C1.33301 15.1372 1.15039 15.2119 0.93457 15.2119C0.51123 15.2119 0.179199 14.8882 0.179199 14.4565C0.179199 14.2407 0.262207 14.0581 0.39502 13.917L6.43799 8.00684L0.39502 2.09668C0.262207 1.95557 0.179199 1.76465 0.179199 1.55713C0.179199 1.12549 0.51123 0.801758 0.93457 0.801758C1.15039 0.801758 1.33301 0.876465 1.48242 1.01758L8.05664 7.45898C8.21436 7.6084 8.29736 7.79932 8.29736 8.00684Z"
        fill={color}
      />
    </svg>
  );
};
