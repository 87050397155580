import React from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { PATHS } from '../../const';
import './not-found.scss';

interface NotFoundProps {
  alert?: string;
  navigateTo?: keyof typeof PATHS | any;
  linkText?: string;
}

export const NotFound = ({ alert, navigateTo, linkText }: NotFoundProps) => {
  const navigate = useNavigate();

  const handleClick = (navigateTo?: keyof typeof PATHS | any) => {
    if (navigateTo) {
      navigate(navigateTo);
    } else {
      navigate(PATHS.main);
    }
  };

  return (
    <Container>
      <div className="not-found">
        <Alert variant="danger">{alert ?? 'Страница не найдена'}</Alert>
        <Button
          onClick={() => handleClick(navigateTo)}
          className="w-100 btn btn-primary">
          {linkText || 'На главную'}
        </Button>
      </div>
    </Container>
  );
};

