import { Controller, useFormContext } from 'react-hook-form';
import { Text, NumberInput } from '../../../../mantine';
import { useEffect, useState } from 'react';
import styles from './CustomNumberInput.module.scss';
import { useMantineTheme } from '@mantine/core';
import { BackedErrorForBlock } from '../BackendErrorForBlock';
export interface CustomNumbertInputProps {
  type: 'input';
  id: string;
  label: string;
  value: string;
  read_only: boolean;
  required: boolean;
  keyboard_type: string;
  error?: string;
}
export const CustomNumberInput = ({
  id,
  keyboard_type,
  label,
  read_only,
  required,
  type,
  value,
  error
}: CustomNumbertInputProps) => {
  const {
    formState: { errors },
    control
  } = useFormContext();
  const theme = useMantineTheme();
  const [isBackendEror, setBackendErorr] = useState<null | string>(null);

  useEffect(() => {
    if (error) {
      setBackendErorr(error);
    }
  }, [error]);

  return (
    <>
      <Controller
        name={id}
        control={control}
        shouldUnregister={true}
        rules={{
          required: required
        }}
        defaultValue={value}
        render={({ field: { ref, ...restField } }) => (
          <NumberInput
            readOnly={read_only}
            styles={{
              input: {
                borderColor: theme.colors.primary[1],
                borderWidth: '0.5px',
                borderStyle: 'solid',
                backgroundColor: 'transparent'
              }
            }}
            placeholder="Введине значение"
            withAsterisk={required}
            hideControls
            {...restField}
            onChange={(event) => {
              restField.onChange(String(event));
              setBackendErorr(null);
            }}
            error={id in errors}
            name={id}
            value={value}
            defaultValue={String(value)}
            classNames={{
              wrapper: styles.wrapper,
              input: styles.customNumberInput,
              root: styles.root
            }}
            label={label}
          />
        )}
      />
      <BackedErrorForBlock text={isBackendEror} />
    </>
  );
};
