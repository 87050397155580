import ProjectItem from '../projects-list-item/project-item';
import { Container } from 'react-bootstrap';
import './projects-list.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import SearchPanel from '../search-panel/search-panel';
import { userId } from '../../helpers/api';
import { ProjectsListType } from '../../models/projects/projects';
import {
  filterProjectByCategory,
  filterProjectsWithOffers
} from '../../helpers/projects';
import Loading from '../../pages/loading/loading';
import ErrorPage from '../../pages/error/error';
import { useEffect } from 'react';
import { setSelectedCategory } from '../../store/slices/appStateSlice';
import {
  restoreScrollPosition,
  saveScrollPosition
} from '../../helpers/scroll';
import { useLocation } from 'react-router-dom';

const ProjectsList = () => {
  const location = useLocation();
  const state = location.state || {};
  const dispatch = useAppDispatch();
  const { isLoadingProjects, error, error_id, projects } = useAppSelector(
    (state) => state.projects
  );

  const { searchProjectValue, selectedCategory } = useAppSelector(
    (state) => state.appState
  );

  const filterProjects = (
    projects: ProjectsListType,
    category: string | null
  ) => {
    if (userId) {
      return category ? filterProjectByCategory(projects, category) : projects;
    }
    return category
      ? filterProjectByCategory(filterProjectsWithOffers(projects), category)
      : filterProjectsWithOffers(projects);
  };

  const searchProjects = (projects: ProjectsListType, search: string) => {
    if (search.length === 0) {
      return projects;
    }
    return projects.filter((p) =>
      p.name.toLowerCase().includes(search.toLowerCase())
    );
  };

  const visibleProjects = searchProjects(
    filterProjects(projects.projects, selectedCategory),
    searchProjectValue
  );

  useEffect(() => {
    if (!selectedCategory) {
      setTimeout(() => {
        restoreScrollPosition();
      }, 0);
    }
  }, []);

  if (isLoadingProjects && state.linkFromMainMenu) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorPage
        alert={`server_error:\n${error}\n\n${error_id}: ${error_id}`}
      />
    );
  }

  return (
    <>
      <Container>
        {selectedCategory ? (
          <div className="projects-list__title">{selectedCategory}</div>
        ) : (
          <SearchPanel />
        )}
        {visibleProjects.length > 0 ? (
          <ul className="projects-list">
            {visibleProjects.map((project) => (
              <ProjectItem project={project} key={project.id} />
            ))}
          </ul>
        ) : (
          <Container>У вас пока нет активных проектов&nbsp;😿</Container>
        )}
      </Container>
    </>
  );
};

export default ProjectsList;
