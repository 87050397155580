import { useState } from 'react';
import './wallet.scss';
import { Container } from 'react-bootstrap';
import Balance from '../../components/balance/balance';
import CustomCard from '../../components/card/card';
import CustomModal from '../../components/modal/modal';
import AddPaymentMethodModal from './paymentMethodModal';
import WalletInfo from '../../components/wallet-info/wallet-info';
import {
  requestPaymentHistory,
  requestUserBalanceDetails
} from '../../api/wallet';
import { useFetchData } from '../../hooks/useFetchData';
import Loading from '../loading/loading';
import ErrorPage from '../error/error';
import { PaymentInfoList, PaymentMethodCardsList } from './components';
import { Button, Loader, Text } from '../../mantine';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { requestPayouts } from '../../store/thunk/walletThunk';
import { cleanPayoutsRequestInfo } from '../../store/slices/walletSlice';
const Wallet = () => {
  const dispatch = useAppDispatch();
  const [showPaymentWithdrawalModal, setShowPaymentWithdrawalModal] =
    useState<boolean>(false);
  const [showPaymentAddModal, setShowPaymentAddModal] =
    useState<boolean>(false);
  const payoutsRequestError = useAppSelector(
    (state) => state.wallet.requestPayouts.error
  );
  const isPayoutsRequestLoading = useAppSelector(
    (state) => state.wallet.requestPayouts.isLoading
  );

  const {
    data: userBalanceDetails,
    error: userBalanceError,
    loading: isLoadingUserBalance
  } = useFetchData(requestUserBalanceDetails);

  const {
    data: paymentHistory,
    loading: isLoadingPaymentHistory,
    error: paymentHistoryError
  } = useFetchData(requestPaymentHistory);

  useTelegramBackButton();

  const hidePaymentWithdrawalModalHandler = () => {
    dispatch(cleanPayoutsRequestInfo());
    setShowPaymentWithdrawalModal(false);
  };

  const withDrawHandler = () => {
    dispatch(requestPayouts());
  };

  const handleShowAvailablePaymentMethods = () => {
    setShowPaymentAddModal((prev) => !prev);
  };
  const handleShowWithDrawalModal = () => {
    setShowPaymentWithdrawalModal(true);
  };

  if (userBalanceError) {
    return <ErrorPage />;
  }
  if (isLoadingUserBalance || isLoadingPaymentHistory) {
    return <Loading />;
  }

  return (
    <>
      <div className="page wallet">
        <Container>
          {userBalanceDetails ? (
            <>
              <Balance
                value={userBalanceDetails.balance}
                hint="Доступно к выводу"
              />
              <WalletInfo paid={userBalanceDetails.paid} />
              <div
                className="tg-text-hint text-center py-3"
                style={{ fontSize: '12px' }}>
                Результаты на&nbsp;проверке проходят финальную сверку.
                До&nbsp;ее завершения вознаграждение недоступно для&nbsp;вывода
              </div>
            </>
          ) : (
            <Text ta={'center'} c={'#FF3B30'}>
              Не удалось загрузить информацию о балансе пользователя!
            </Text>
          )}
          <PaymentMethodCardsList />
          <div className={'btns-container'}>
            <Button
              color={'var(--tg-theme-button-color)'}
              onClick={handleShowAvailablePaymentMethods}
              w={'100%'}
              mb={'5px'}>
              Привязать метод
            </Button>
            <Button
              onClick={handleShowWithDrawalModal}
              w={'100%'}
              color={'var(--tg-theme-button-color)'}>
              Вывести
            </Button>
          </div>
        </Container>
        <CustomCard className="payments">
          <PaymentInfoList paymentHistory={paymentHistory} />
        </CustomCard>
      </div>
      <CustomModal
        title="Вывод средств"
        show={showPaymentWithdrawalModal}
        hideHandler={hidePaymentWithdrawalModalHandler}
        closeBtn={false}>
        {isPayoutsRequestLoading ? (
          <div className="requestPayoutsLoaderWrapper">
            <Loader />
          </div>
        ) : (
          <div>
            {payoutsRequestError?.message ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ textAlign: 'center' }}>
                  {payoutsRequestError?.message}
                </p>
                <button
                  className="btn-custom btn-custom--primary"
                  type="button"
                  onClick={hidePaymentWithdrawalModalHandler}>
                  Закрыть
                </button>
              </div>
            ) : (
              <>
                <div className="text-center">
                  Деньги поступят на&nbsp;счет в&nbsp;течении трех рабочих дней.
                </div>
                <div className="custom-modal__controls">
                  <button
                    className="btn-custom btn-custom--secondary"
                    type="button"
                    onClick={hidePaymentWithdrawalModalHandler}>
                    Отмена
                  </button>
                  <button
                    className="btn-custom btn-custom--primary"
                    type="button"
                    onClick={withDrawHandler}>
                    Вывести
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </CustomModal>
      <AddPaymentMethodModal
        show={showPaymentAddModal}
        setShow={handleShowAvailablePaymentMethods}
      />
    </>
  );
};

export default Wallet;
