import React from 'react';
import {
  TextFile,
  CustomTitle,
  CustomText,
  CustomTextInput,
  CustomButton,
  CustomCheckBox,
  CustomSwitch,
  CustomFileCollection,
  HtmlText,
  SelectableCardList,
  CopyableTextList,
  TextFieldWithSwitcher,
  PmCard,
  PmInfo,
  EmojiProps,
  Emoji,
  CustomError,
  CustomErrorProps,
  CustomNumberInput,
  DropDown,
  type TextFileProps,
  type CustomTitleProps,
  type CustomTextProps,
  type CustomTextInputProps,
  type CustomButtonProps,
  type CustomCheckBoxProps,
  type CustomSwitchProps,
  type CustomFileCollectionProps,
  type CustomNumbertInputProps,
  type HtmlTextProps,
  type SelectableCardListProps,
  type CopyableTextListProps,
  type TextFieldWithSwitcherProps,
  type PmInfoProps,
  type DropDownProps,
  CalendarProps,
  Calendar
} from './components';
export interface RedirectBlock {
  type: 'redirect';
  id: string;
  url: string;
}
export interface SendButtonBlock {
  type: 'send_button';
  id: string;
  label: string;
}

export interface StepIndicator {
  type: 'step_indicator';
  current_step: number;
  id: string;
  steps_count: number;
}
export interface FloatingButton {
  type: 'send_button';
  id: string;
  label: string;
}
export interface PmCardBlock {
  type: 'pm_card';
  id: string;
  title: string;
  subtitle: string;
  picture: string;
}

export type Block =
  | CustomTitleProps
  | HtmlTextProps
  | CustomTextProps
  | CustomTextInputProps
  | CustomButtonProps
  | CustomCheckBoxProps
  | CustomSwitchProps
  | CustomFileCollectionProps
  | TextFileProps
  | SelectableCardListProps
  | CopyableTextListProps
  | TextFieldWithSwitcherProps
  | PmCardBlock
  | PmInfoProps
  | EmojiProps
  | CustomErrorProps
  | RedirectBlock
  | SendButtonBlock
  | StepIndicator
  | DropDownProps
  | CalendarProps;

export type BlockType = Block['type'];

export type ComponentTypeMap = {
  title: React.ComponentType<CustomTitleProps>;
  html_text: React.ComponentType<HtmlTextProps>;
  text: React.ComponentType<CustomTextProps>;
  textInput: React.ComponentType<CustomTextInputProps>;
  button: React.ComponentType<CustomButtonProps>;
  checkbox: React.ComponentType<CustomCheckBoxProps>;
  switch: React.ComponentType<CustomSwitchProps>;
  file_collection: React.ComponentType<CustomFileCollectionProps>;
  file: React.ComponentType<TextFileProps>;
  selectable_card_list: React.ComponentType<SelectableCardListProps>;
  copyable_text_list: React.ComponentType<CopyableTextListProps>;
  textfield_with_switcher: React.ComponentType<TextFieldWithSwitcherProps>;
  pm_card: React.ComponentType<PmCardBlock>;
  pm_info: React.ComponentType<PmInfoProps>;
  emoji: React.ComponentType<EmojiProps>;
  error: React.ComponentType<CustomErrorProps>;
  numberInput: React.ComponentType<CustomNumbertInputProps>;
  dropdown: React.ComponentType<DropDownProps>;
  date: React.ComponentType<CalendarProps>;
};
export const MODEL: ComponentTypeMap = {
  title: CustomTitle,
  text: CustomText,
  textInput: CustomTextInput,
  button: CustomButton,
  checkbox: CustomCheckBox,
  switch: CustomSwitch,
  file_collection: CustomFileCollection,
  html_text: HtmlText,
  file: TextFile,
  selectable_card_list: SelectableCardList,
  copyable_text_list: CopyableTextList,
  textfield_with_switcher: TextFieldWithSwitcher,
  pm_card: PmCard,
  pm_info: PmInfo,
  emoji: Emoji,
  error: CustomError,
  numberInput: CustomNumberInput,
  dropdown: DropDown,
  date: Calendar
};
export enum STEPS {
  STEP_FIRST = 'STEP_FIRST',
  STEP_SECOND = 'STEP_SECOND',
  STEP_THIRD = 'STEP_THIRD',
  SUCCESS = 'SUCCESS',
  REQUEST_ACCEPT = 'REQUEST_ACCEPT'
}

export interface CommonBlockTypes {
  type: BlockType;
  id: string;
  error?: string;
}
export interface Data {
  page: Block[];
  floating_button: FloatingButton;
  step: STEPS;
  is_final?: boolean;
}

export const SendButton = 'send_button';
export const Redirect = 'redirect';
export const StepIndicator = 'step_indicator';
// временно, пока нет загрузки файлов
export const FileCollection = 'file_collection';

export const isNotUiBlocks = [
  Redirect,
  SendButton,
  StepIndicator,
  FileCollection
];
