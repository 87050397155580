import { Container } from 'react-bootstrap';
import { TIPS } from '../../const';
import { Swiper, SwiperSlide } from 'swiper/react';
import { nanoid } from 'nanoid';
import './tips.scss';
import 'swiper/css';
import PageTitle from '../../components/page-title/page-title';
import { CustomIcons, IconSource } from '../../enums';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

const Tips = () => {
  const icon = {
    name: CustomIcons.binoculars_lg,
    source: IconSource.custom
  };
  useTelegramBackButton();
  return (
    <div className="page tips">
      <PageTitle
        icon={icon}
        iconColor={'var(--tg-theme-text-color)'}
        title="Где продать"
        subtitle="7 способов как помочь близким и друзьям сэкономить деньги"
      />
      <div className="page-content">
        <div className="tips__slider">
          <Container>
            <Swiper
              spaceBetween={10}
              slidesPerView={'auto'}
              centeredSlides={true}>
              {TIPS.map((tip, index) => (
                <SwiperSlide className="tips__slider-item" key={nanoid(4)}>
                  <div
                    className={`tips__slider-image tips__slider-image--${
                      index + 1
                    }`}></div>
                  <div>
                    <div className="tips__slider-title">{tip.title}</div>
                    <div className="tips__slider-desc">{tip.description}</div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Tips;
