import { createSlice } from '@reduxjs/toolkit';
import { type AdditionalData } from '../../types/api';
import { fetchReferralsGeneralInfo } from '../thunk/ReferralsThunk';
import { ReferralsDataType } from '../../models/referrals/referrals-info';

interface ReferralsState {
  loadingReferrals: boolean;
  errorReferralInfo?: string;
  errorIdReferralInfo?: string;
  referralsInfo: null | ReferralsDataType;
  additionalData: AdditionalData;
}

const initialState: ReferralsState = {
  loadingReferrals: false,
  errorReferralInfo: '',
  errorIdReferralInfo: '',
  referralsInfo: null,
  additionalData: {
    user_id: 0,
    blogger_id: null
  }
};

export const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReferralsGeneralInfo.pending, (state) => {
      state.loadingReferrals = true;
      state.errorReferralInfo = '';
    });
    builder.addCase(fetchReferralsGeneralInfo.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.referralsInfo = data;
      state.loadingReferrals = false;
      state.errorReferralInfo = '';
    });
    builder.addCase(fetchReferralsGeneralInfo.rejected, (state, action) => {
      state.errorReferralInfo = action.payload?.message;
      state.errorIdReferralInfo =
        action.payload?.response?.headers['x-request-id'];
      state.loadingReferrals = false;
    });
  }
});

export default referralsSlice.reducer;
