import { type SvgIconProps } from './types';

export const ErrorIcon = ({ color = '#FF3B30', ...rest }: SvgIconProps) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M2.5918 16.0586C1.11426 16.0586 0.167969 14.9712 0.167969 13.6348C0.167969 13.228 0.275879 12.8047 0.5 12.4146L6.9165 1.2251C7.37305 0.419922 8.17822 0.00488281 9 0.00488281C9.81348 0.00488281 10.6187 0.411621 11.0752 1.2251L17.4917 12.4062C17.7158 12.8047 17.8237 13.228 17.8237 13.6348C17.8237 14.9712 16.8774 16.0586 15.3999 16.0586H2.5918ZM9.0083 10.2646C9.52295 10.2646 9.82178 9.97412 9.83838 9.42627L9.97119 5.5083C9.99609 4.95215 9.58105 4.56201 9 4.56201C8.40234 4.56201 8.00391 4.95215 8.02881 5.5083L8.15332 9.43457C8.16992 9.96582 8.47705 10.2646 9.0083 10.2646ZM9.0083 13.1782C9.60596 13.1782 10.0874 12.7798 10.0874 12.1904C10.0874 11.6094 9.61426 11.1943 9.0083 11.1943C8.40234 11.1943 7.9209 11.6094 7.9209 12.1904C7.9209 12.7715 8.40234 13.1782 9.0083 13.1782Z"
        fill={color}
      />
    </svg>
  );
};
