import { ComboboxData, useMantineTheme } from '@mantine/core';
import { Select } from '../../../../mantine';
import styles from './DropDown.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { BackedErrorForBlock } from '../BackendErrorForBlock';

export interface DropDownProps {
  type: 'dropdown';
  id: string;
  label: string;
  selectedValue: string;
  availableValues: Record<string, string>;
  required: boolean;
  multiple: boolean;
}

const prepareValuesForSelect = (availableValues: Record<string, string>) => {
  const array: Record<string, string>[] = [];
  Object.entries(availableValues).forEach((item) => {
    array.push({ value: item[0], label: item[1] });
  });
  return array;
};

export const DropDown = ({
  availableValues,
  id,
  label,
  multiple,
  required,
  selectedValue,
  type
}: DropDownProps) => {
  const {
    register,
    formState: { errors },
    control
  } = useFormContext();
  const theme = useMantineTheme();
  const [backedError, setBackendErorr] = useState<string | null>(null);
  const data = prepareValuesForSelect(availableValues);

  return (
    <div className={styles.wrapper}>
      <Controller
        name={id}
        control={control}
        shouldUnregister={true}
        rules={{ required: required }}
        defaultValue={selectedValue}
        render={({ field: { ref, ...restField } }) => (
          <Select
            placeholder="Выберите название банка"
            styles={{
              input: {
                borderColor: theme.colors.primary[1],
                borderWidth: '0.5px',
                borderStyle: 'solid',
                backgroundColor: 'transparent',
                height: '44px'
              },
              root: {
                width: '100%'
              }
            }}
            required={required}
            data={data as any}
            withAsterisk={required}
            {...restField}
            onChange={(event) => {
              restField.onChange(event);
              setBackendErorr(null);
            }}
            error={id in errors}
            name={id}
            defaultValue={selectedValue}
            label={label}
          />
        )}
      />
      <BackedErrorForBlock text={backedError} />
    </div>
  );
};
