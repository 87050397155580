import { AxiosError } from 'axios';
import { sendError } from '../api/api';
import { userId } from './api';
import { TG } from './telegram';
import { resolveLoggerInstance } from './logger';

const logger = resolveLoggerInstance('fetchReferralsList');

export const sendKeyMissingError = () => {
  sendError({
    url: window.location.href,
    chat_id: userId,
    message: 'Key is missing'
  });
};

export const sendChatIdMissingError = () => {
  sendError({
    url: window.location.href,
    chat_id: userId,
    message: 'Chat id is NULL',
    additionalData: TG.WebApp?.initDataUnsafe
  });
};

export const handleApiError = async (error: AxiosError) => {
  logger.error(error);
  const xRequestId = error.response?.headers['x-request-id'];
  sendError({
    url: error.config?.url,
    chat_id: userId,
    message: error.message,
    error_id: xRequestId,
    code: error.code,
    cause: error.cause?.message,
    status: error.status,
    request: JSON.stringify(error.request)
  });
};
