import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appStateReducer from './slices/appStateSlice';
import projectsReducer from './slices/projectsSlice';
import referralsSlice from './slices/referralsSlice';
import participateInProjectSlice from './slices/participateInProjectSlice';
import { participateInProject } from '../api/projects';
import walletSliceReducer from './slices/walletSlice';

const rootReducer = combineReducers({
  projects: projectsReducer,
  appState: appStateReducer,
  referrals: referralsSlice,
  participateInProjectSlice: participateInProjectSlice,
  wallet: walletSliceReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
