import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/redux';
import parseHtml from 'html-react-parser';
import { Container } from 'react-bootstrap';
import PageHeader from '../../../components/page-header/page-header';
import { NotFound } from '../../not-found/not-found';
import PageContent from '../../../components/page-content/page-content';
import { TG } from '../../../helpers/telegram';
import { IoHandLeft } from 'react-icons/io5';
import './forbidden.scss';


const ProjectForbidden = () => {
  const { currentProject } = useAppSelector((state) => state.projects);

  if (!currentProject) {
    return (
      <NotFound
        alert="Не удалось получить информацю о запретах"
        linkText="Назад к проекту"
        navigateTo={-1}
      />
    );
  }
  const { forbidden, name } = currentProject.project;

  useEffect(() => {
    TG.MainButton?.hide();
    window.scroll(0, 0);
  }, []);

  return (
    <div className="project-forbidden">
      <PageHeader title={'Запрещено'} subtitle={name} icon={<IoHandLeft />} />
      <PageContent>
        <Container>
          {forbidden && (
            <div className="project-forbidden__bonuses-list mb-4">
              <div className="text-md forbidden-conditions">
                {parseHtml(forbidden)}
              </div>
            </div>
          )}
        </Container>
      </PageContent>
    </div>
  );
};

export default ProjectForbidden;
