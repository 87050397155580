import { isDev, isProd } from './common';
import { config } from "../config";

export enum LogLevels {
  'silent' = -1,
  'error' = 0,
  'warn' = 1,
  'log' = 2,
  'debug' = 3,
  'trace' = 4
}

type LoggerOptions = {
  level?: number;
};

interface Logger extends LoggerOptions {
  log(...messages: unknown[]): void;
  warn(...messages: unknown[]): void;
  error(...messages: unknown[]): void;
}

const loggers: Record<string, Logger> = {};

class ConsoleLogger implements Logger {
  private readonly token: string;
  public level;

  constructor(token: string, options?: LoggerOptions) {
    this.token = token;
    this.level = options?.level ?? this.getDefaultLogLevel();
  }

  private getDefaultLogLevel(): LogLevels {
    if (config.logLevel) {
      return Number.parseInt(config.logLevel);
    }

    if (isDev()) {
      return LogLevels.debug;
    }

    if (isProd()) {
      return LogLevels.silent;
    }

    return LogLevels.log;
  }

  private canShow(level: LogLevels) {
    return this.level >= level;
  }

  error(...messages: unknown[]) {
    if (!this.canShow(LogLevels.error)) {
      return;
    }

    console.error(this.token, ...messages);
  }

  warn(...messages: unknown[]) {
    if (!this.canShow(LogLevels.error)) {
      return;
    }

    console.warn(this.token, ...messages);
  }

  log(...messages: unknown[]) {
    if (!this.canShow(LogLevels.error)) {
      return;
    }

    console.log(this.token, ...messages);
  }

  dir(...messages: unknown[]) {
    if (!this.canShow(LogLevels.debug)) {
      return;
    }

    console.dir(this.token, ...messages);
  }

  trace(...messages: unknown[]) {
    if (!this.canShow(LogLevels.trace)) {
      return;
    }

    console.trace(this.token, ...messages);
  }
}

export const resolveLoggerInstance = (token: string, options?: LoggerOptions) => {
  if (loggers[token]) {
    return loggers[token];
  }

  const newLogger = new ConsoleLogger(token, options);
  loggers[token] = newLogger;

  return newLogger;
};
