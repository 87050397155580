import styles from './PmCard.module.scss';
import { TG } from '../../../../helpers/telegram';
import { colorSchemes } from '../../../../enums';
import { type PmCardBlock } from '../../componentsModel';
import cn from 'classnames';
import { SelectedCheck } from '../../icons';

interface PmCardProps extends PmCardBlock {
  wrapperStyle?: string;
  selectedId?: string | null;
  setSelected?: (id: any) => void;
}

export const PmCard = ({
  id,
  picture,
  subtitle,
  title,
  type,
  wrapperStyle,
  selectedId,
  setSelected
}: PmCardProps) => {
  const scheme = TG.WebApp?.colorScheme;

  const handleClick = (id: string) => {
    if (setSelected && id) {
      setSelected(id);
    }
  };

  return (
    <div
      // потом поменять на id, сейчас приходить просто один и тот же id
      onClick={() => handleClick(title)}
      className={cn(styles.pmCardWrapper, wrapperStyle)}
      style={{
        backgroundColor: scheme === colorSchemes.dark ? 'white' : '#76768014'
      }}>
      <img src={picture} className={styles.pmCardPicture}></img>
      <div className={styles.pmCardInfoWrapper}>
        <p className={styles.pmCardTitle}>{title}</p>
        <p className={styles.pmCardSubtitle}>{subtitle}</p>
      </div>
      {/* потом поменять на id, сейчас приходить просто один и тот же id */}
      {selectedId === title && (
        <SelectedCheck
          className={styles.selectedCheck}
          width={'20px'}
          height={'20px'}
        />
      )}
    </div>
  );
};
