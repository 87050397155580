import { Category } from '../types/state';
import { ProjectsListType, ProjectsListItemType } from '../models/projects/projects';
import { filterProjectsWithOffers } from './projects';

export const filterCategories = (categories: Category[], projects: ProjectsListType) => {
  return categories.filter((category) => {
    return filterProjectsWithOffers(projects).filter((project) => {
      return project.categories.some((category) => category.id === category.id);
    }).length;
  });
};
