import { type SvgIconProps } from './types';

export const FileIcon = ({ color = '#AEAEB2', ...rest }: SvgIconProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M15.0737 29.2471C13.1533 29.2471 12.1978 28.2822 12.1978 26.3433V12.2695C12.1978 10.3398 13.1626 9.35645 15.0737 9.35645H19.4805C20.5195 9.35645 21.104 9.51416 21.8091 10.2378L26.9209 15.4424C27.6631 16.2031 27.8022 16.7227 27.8022 17.9194V26.3433C27.8022 28.2729 26.8467 29.2471 24.9263 29.2471H15.0737ZM15.1387 27.7534H24.8521C25.8169 27.7534 26.3086 27.2432 26.3086 26.3154V17.9844H21.0205C19.8608 17.9844 19.2764 17.4092 19.2764 16.2402V10.8501H15.1479C14.1831 10.8501 13.6914 11.3789 13.6914 12.2974V26.3154C13.6914 27.2432 14.1831 27.7534 15.1387 27.7534ZM21.1875 16.5835H26.021L20.6772 11.1377V16.064C20.6772 16.4351 20.8164 16.5835 21.1875 16.5835ZM23.4141 20.5449C23.7388 20.5449 23.98 20.7954 23.98 21.1108C23.98 21.4355 23.7388 21.686 23.4141 21.686H16.3726C16.0386 21.686 15.7881 21.4355 15.7881 21.1108C15.7881 20.7954 16.0386 20.5449 16.3726 20.5449H23.4141ZM23.4141 23.7827C23.7388 23.7827 23.98 24.0425 23.98 24.3672C23.98 24.6826 23.7388 24.9238 23.4141 24.9238H16.3726C16.0386 24.9238 15.7881 24.6826 15.7881 24.3672C15.7881 24.0425 16.0386 23.7827 16.3726 23.7827H23.4141Z"
        fill={color}
      />
    </svg>
  );
};
