import { type SvgIconProps } from './types';

export const SelectedCheck = ({ color = '#19D160', ...rest }: SvgIconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M8.95264 17.4736C4.3291 17.4736 0.48584 13.6304 0.48584 9.00684C0.48584 4.375 4.3208 0.540039 8.94434 0.540039C13.5762 0.540039 17.4194 4.375 17.4194 9.00684C17.4194 13.6304 13.5845 17.4736 8.95264 17.4736ZM8.02295 13.0659C8.30518 13.0659 8.5459 12.9331 8.72021 12.6592L12.6216 6.5166C12.7212 6.34229 12.8291 6.15137 12.8291 5.96875C12.8291 5.57861 12.4888 5.32959 12.1235 5.32959C11.9077 5.32959 11.6919 5.4624 11.5342 5.71143L7.98975 11.4058L6.30469 9.23096C6.09717 8.95703 5.91455 8.88232 5.67383 8.88232C5.30029 8.88232 5.00977 9.18945 5.00977 9.56299C5.00977 9.75391 5.08447 9.93652 5.20898 10.1025L7.29248 12.6592C7.5083 12.9497 7.74072 13.0659 8.02295 13.0659Z"
        fill={color}
      />
    </svg>
  );
};
