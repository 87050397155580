import { ReactNode } from 'react';
import './card.scss';

interface CustomCardProps {
  children: ReactNode;
  bgColor?: string;
  className?: string;
}

const CustomCard = ({ children, bgColor, className = '' }: CustomCardProps) => {
  return (
    <div
      className={`custom-card ${className}`}
      style={{ backgroundColor: bgColor }}>
      {children}
    </div>
  );
};

export default CustomCard;
