import PromocodeItem from './promocode/promocode';
import LinkForSubs from './link-for-subs/link-for-subs';
import CustomIcon from '../icons/custom';
import { CustomIcons } from '../../enums';
import { LandingListItemType } from '../../models/projects/promocodes';
import './group.scss';
import { useParams } from 'react-router-dom';
import { fetchParticipateInProject } from '../../store/thunk/ProjectsThunk';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectIsLoading } from '../../store/slices/participateInProjectSlice';
import { GoalType } from '../../models/projects/projects';
import cn from 'classnames';

interface GroupItemProps {
  projectId: number;
  projectName: string;
  landingGroup: LandingListItemType;
  goals: GoalType[];
  has_promocodes: boolean | undefined;
}

const GroupItem = ({ landingGroup, goals, has_promocodes }: GroupItemProps) => {
  const dispatch = useAppDispatch();
  const { landing, promos, links } = landingGroup;
  const params = useParams();
  const { id } = params;
  const isLoading = useAppSelector((state) =>
    selectIsLoading(state.participateInProjectSlice)
  );

  /* replace can_request_promo with landing.can_request_promo or whatever comes from backend */
  /* replace isRequestingPromo or isRequestingLink with value from requestPromo or requestLink handler (will be in hook) */
  const can_request_promo = true;
  const isRequestingPromo = false;

  const shouldRenderLinks =
    links.length > 0 && promos.length === 0 && !has_promocodes;

  return (
    <>
      <li className="group">
        <div className="group__title">
          <CustomIcon
            icon={CustomIcons.screen}
            color={'var(--tg-theme-text-color)'}
            className="group__img"
          />
          {landing ? <span className="group__name">{landing.name}</span> : ''}
        </div>

        {/* промокоды с переходом */}
        {promos.length > 0 && (
          <div className="group__promocodes">
            {promos.map((promo) => (
              <PromocodeItem
                promo={promo}
                link={links ? links[0].link : ''}
                key={promo.code}
                goals={goals}
              />
            ))}
          </div>
        )}
        {/* линки для перехода на сайт */}
        {shouldRenderLinks && (
          <div className="group__links">
            {links.map((link) => (
              <LinkForSubs key={link.link} linkItem={link} />
            ))}
          </div>
        )}

        {promos.length === 0 && has_promocodes ? (
          <button
            disabled={isLoading}
            className="btn-main"
            type="button"
            onClick={() => {
              if (id) {
                dispatch(
                  fetchParticipateInProject({
                    landing_id: landing.id,
                    project_id: Number(id)
                  })
                );
              }
            }}>
            <span
              className={cn({
                ['animation-flash']: isLoading
              })}>
              Получить промокод
            </span>
          </button>
        ) : (
          links.length === 0 && (
            <button
              className="btn-main"
              type="button"
              onClick={() => {
                if (id) {
                  dispatch(
                    fetchParticipateInProject({
                      landing_id: landing.id,
                      project_id: Number(id)
                    })
                  );
                }
              }}>
              <span
                className={cn({
                  ['animation-flash']: isLoading
                })}>
                Получить ссылку
              </span>
            </button>
          )
        )}
      </li>
    </>
  );
};

export default GroupItem;
