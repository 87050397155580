import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyButton from '../../buttons/copy-button';
import './promocode.scss';
import { TG } from '../../../helpers/telegram';
import CustomIcon from '../../icons/custom';
import { CustomIcons } from '../../../enums';
import { PromocodeInfoType } from '../../../models/projects/promocodes';
import { localizeDate } from '../../../helpers/date';
import { GoalType } from '../../../models/projects/projects';

interface PromocodeBonusProps {
  value: string;
}
interface PromocodeItemProps {
  promo: PromocodeInfoType;
  link: string;
  goals: GoalType[];
}
const PromocodeBonus = ({ value }: PromocodeBonusProps) => {
  return (
    <div className="bonusWrapper">
      <CustomIcon
        icon={CustomIcons.wallet}
        color="var(--tg-theme-link-color)"
      />
      <p style={{ marginBottom: '0' }}>{`Оплата: ${value}`}</p>
    </div>
  );
};
const PromocodeItem = ({ promo, link, goals }: PromocodeItemProps) => {
  const { code, description_long, date_end, goal_ids } = promo;
  const promoGoals = goals.filter((goal) => goal_ids.includes(goal.id));

  return (
    <div className="promo">
      <div className="promo__description">
        <CustomIcon
          icon={CustomIcons.gift}
          color="var(--tg-theme-link-color)"
          style={{ marginBottom: '4px' }}
        />
        &nbsp;{description_long}
      </div>
      <div className="bonusesList">
        {promoGoals.map((goal) => (
          <PromocodeBonus value={goal.prices.fix} key={goal.id} />
        ))}
      </div>
      <div className="control">
        <div className="control__content">
          <>
            <span>
              Промокод: <span className="control__text">{code}</span>
            </span>
            <span className="control__text--right">
              до&nbsp;{localizeDate(date_end)}
            </span>
          </>
        </div>
        <CopyButton text={code} className="control__button" />
      </div>
      <CopyToClipboard
        text={code}
        onCopy={() => {
          setTimeout(() => TG.WebApp?.openLink(link));
        }}>
        <button className="btn-main">Скопировать и перейти</button>
      </CopyToClipboard>
    </div>
  );
};

export default PromocodeItem;
