import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomAlert from '../../../../components/alert/alert';
import { colorSchemes } from '../../../../enums';
import { TG } from '../../../../helpers/telegram';
import { useAppSelector } from '../../../../hooks/redux';

export const PaymentInfoConditions = () => {
  const paymentMethod = useAppSelector((state) => state.appState.paymentMethod);
  if (!paymentMethod?.instruction_text) return null;

  return (
    <CustomAlert variant="warning" className="mb-3">
      <div className="d-flex align-items-start">
        <FontAwesomeIcon
          className="mt-1 me-2"
          icon="circle-info"
          color={
            TG.WebApp?.colorScheme === colorSchemes.light
              ? 'var(--orange)'
              : '#ffffff'
          }
        />
        <div>{paymentMethod.instruction_text}</div>
      </div>
    </CustomAlert>
  );
};
