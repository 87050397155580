import styles from './PmInfo.module.scss';

export interface PmInfoProps {
  type: 'pm_info';
  id: string;
  pm_slug: string;
}
export const PmInfo = ({ id, pm_slug }: PmInfoProps) => {
  return <div>PmInfo</div>;
};
