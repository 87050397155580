import { z } from 'zod';

export const promocodeInfoSchema = z.object({
  bonus_id: z.number(),
  is_active: z.boolean(),
  is_expired: z.boolean(),
  code: z.string(),
  date_start: z.string(),
  date_end: z.string(),
  description_short: z.string(),
  description_long: z.string(),
  goal_ids: z.array(z.number())
});

export const landingInfoSchema = z.object({
  id: z.number(),
  name: z.string(),
  url: z.string(),
  plain_url: z.string(),
  allow_deep: z.boolean(),
  tutorial_video: z.string().optional().nullable()
});

export const linkSchema = z.object({
  title: z.string(),
  landing_id: z.number(),
  link: z.string()
});

export const landingListItemSchema = z.object({
  landing: landingInfoSchema,
  promos: z.array(promocodeInfoSchema),
  links: z.array(linkSchema)
});

export const landingListSchema = z.array(landingListItemSchema);

export const promocodesSchema = z.object({
  id: z.number(),
  landings: landingListSchema
});

export type LandingInfoType = z.infer<typeof landingInfoSchema>;
export type LandingListItemType = z.infer<typeof landingListItemSchema>;
export type LandingListType = z.infer<typeof landingListSchema>;
export type LinkType = z.infer<typeof linkSchema>;
export type PromocodeInfoType = z.infer<typeof promocodeInfoSchema>;
export type PromocodesType = z.infer<typeof promocodesSchema>;
