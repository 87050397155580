import { z } from 'zod';

export const referralListItemSchema = z.object({
  name: z.string(),
  created_at: z.string(),
  status: z.string(),
  status_color: z.string(),
  reward_total: z.string()
});

export const referralListDataSchema = z.object({
  referrals: z.array(referralListItemSchema)
});

export type ReferralListItemType = z.infer<typeof referralListItemSchema>;
export type ReferralListDataType = z.infer<typeof referralListDataSchema>;
