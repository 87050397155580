import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../const';
import { TG } from '../helpers/telegram';
import { useAppSelector } from './redux';
import { useDispatch } from 'react-redux';
import { setSelectedCategory } from '../store/slices/appStateSlice';
const isOnProjectPage = /^\/project\/\d+$/;

export const useTelegramBackButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { selectedCategory } = useAppSelector((state) => state.appState);

  useEffect(() => {
    const handleBackButton = () => {
      const shouldNavigateToMain = [
        PATHS.project,
        PATHS.projects_list,
        PATHS.wallet,
        PATHS.referral,
        PATHS.tips,
        PATHS.projects_categories
      ].includes(pathname);

      if (pathname === PATHS.referrals) {
        navigate(PATHS.referral);
        return;
      }

      if (
        pathname.includes('/forbidden') ||
        pathname.includes('/bonuses') ||
        pathname.includes('/about')
      ) {
        const lastIndex = pathname.lastIndexOf('/');
        const projectUrl = pathname.substring(0, lastIndex);
        navigate(projectUrl);
        return;
      }

      if (isOnProjectPage.test(pathname)) {
        navigate(PATHS.projects_list);
        return;
      }

      if (selectedCategory && pathname === PATHS.projects_list) {
        dispatch(setSelectedCategory(null));
        navigate(PATHS.projects_categories);
        return;
      }

      if (selectedCategory) {
        navigate(PATHS.projects);
        return;
      }
      if (shouldNavigateToMain) {
        navigate(PATHS.main);
        return;
      }
    };

    TG.BackButton?.onClick(handleBackButton);

    if (pathname === PATHS.main || pathname === PATHS.error) {
      TG.BackButton?.hide();
    } else {
      TG.BackButton?.show();
    }

    return () => {
      TG.BackButton?.offClick(handleBackButton);
    };
  }, [pathname, navigate, selectedCategory]);
};
