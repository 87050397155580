const storage = import.meta.env;

export const config = {
  isTest: storage.VITE_TEST_ENV,
  dev: storage.DEV,
  prod: storage.PROD,
  logLevel: storage.VITE_LOG_LEVEL,
  telegramUser: storage.VITE_TELEGRAM_USER,
  apiHost: storage.VITE_API_HOST
};
