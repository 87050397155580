import { z } from 'zod';
import { categorySchema } from './categories';

export const regionSchema = z.object({
  country: z.string(),
  cities: z.string()
});

export const subscriberBonusSchema = z.object({
  text: z.string(),
  is_new: z.boolean()
});

const goalSchema = z.object({
  name: z.string(),
  id: z.number(),
  description: z.string(),
  cpa_type: z.string(),
  prices: z.object({
    tax: z.string().nullable(),
    fix: z.string(),
    max_fee: z.string().nullable()
  })
});


export const projectInfoSchema = z.object({
  id: z.number(),
  logo: z.string(),
  name: z.string(),
  site: z.string(),
  product_info: z.string(),
  forbidden: z.string(),
  bonus_condition: z.string(),
  subscribers_bonuses_objects: z.array(subscriberBonusSchema),
  allowed_platforms: z.array(z.string()),
  allowed_regions: z.array(regionSchema),
  goals: z.array(goalSchema),
  has_promocodes: z.boolean().optional()
});

export const projectsListItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  logo: z.any(),
  offers_count: z.number(),
  categories: z.array(categorySchema)
});


export const projectsListSchema = z.array(projectsListItemSchema);

export type GoalType = z.infer<typeof goalSchema>;
export type RegionType = z.infer<typeof regionSchema>;
export type SubscriberBonusType = z.infer<typeof subscriberBonusSchema>;
export type ProjectInfoType = z.infer<typeof projectInfoSchema>;
export type ProjectsListItemType = z.infer<typeof projectsListItemSchema>;
export type ProjectsListType = z.infer<typeof projectsListSchema>;
