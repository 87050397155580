import styles from './CustomButton.module.scss';
import { Button } from '../../../../mantine';
import { Link } from 'react-router-dom';
import { TG } from '../../../../helpers/telegram';
export interface CustomButtonProps {
  type: 'button';
  id: string;
  label: string;
  url: string;
}
export const CustomButton = ({ id, label, type, url }: CustomButtonProps) => {
  const handleClick = () => {
    TG?.OpenLink?.(url);
  };
  return (
    <a
      style={{ color: 'white' }}
      className={styles.customButton}
      type="button"
      onClick={handleClick}>
      {label}
    </a>
  );
};
