import { type SvgIconProps } from './types';

export const CheckMark = ({ color = '#40c057', ...rest }: SvgIconProps) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M4.96191 13.1792C4.64697 13.1792 4.41992 13.04 4.2002 12.7544L0.398926 7.91309C0.259766 7.7373 0.186523 7.54688 0.186523 7.37842C0.186523 6.97559 0.472168 6.68994 0.889648 6.68994C1.15332 6.68994 1.33643 6.79248 1.51953 7.04883L4.93262 11.5166L11.5098 1.03564C11.6855 0.757324 11.8467 0.662109 12.1396 0.662109C12.5425 0.662109 12.8062 0.925781 12.8062 1.32861C12.8062 1.49707 12.7549 1.6582 12.623 1.87061L5.70166 12.769C5.52588 13.04 5.27686 13.1792 4.96191 13.1792Z"
        fill={color}
      />
    </svg>
  );
};
