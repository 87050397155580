import React, { ReactNode } from 'react';
import './alert.scss';
import { Alert } from 'react-bootstrap';
import { TG } from '../../helpers/telegram';

interface Props {
  children: ReactNode;
  className?: string;
  variant:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
}

const CustomAlert = ({
  children,
  variant,
  className = ''
}: Props): JSX.Element => {
  return (
    <Alert
      style={{
        color: 'var(--tg-theme-text-color)',
        backgroundColor:
          TG.WebApp?.colorScheme === 'light' ? '#FFF8EE' : '#FF9500'
      }}
      className={`custom-alert ${className}`}
      variant={variant}>
      {children}
    </Alert>
  );
};

export default CustomAlert;
