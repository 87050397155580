import styles from './TextFile.module.scss';
import { Link } from 'react-router-dom';
import { FileIcon, ArrowRight } from '../../icons';
import { Button, Text } from '../../../../mantine';
import { TG } from '../../../../helpers/telegram';
export interface TextFileProps {
  type: 'file';
  id: string;
  label: string;
  url: string;
}
export const TextFile = ({ id, label, type, url }: TextFileProps) => {
  return (
    <a
      type="button"
      className={styles.textFile}
      onClick={() => TG?.OpenLink?.(url)}>
      <FileIcon />
      <Text>{label}</Text>
      <ArrowRight className={styles.arrowRight} />
    </a>
  );
};
