import { TextInput, Text } from '../../../../mantine';
import { TextInputProps, useMantineTheme } from '@mantine/core';
import styles from './CustomTextInput.module.scss';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { BackedErrorForBlock } from '../BackendErrorForBlock';

export interface CustomTextInputProps extends TextInputProps {
  type: 'input';
  id: string;
  label: string;
  value: string;
  read_only: boolean;
  required: boolean;
  keyboard_type: 'text';
  error?: string;
}

export const CustomTextInput = ({
  required = false,
  read_only = false,
  label = 'label',
  value = '',
  id,
  error
}: CustomTextInputProps) => {
  const theme = useMantineTheme();
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const [isBackendEror, setBackendErorr] = useState<null | string>(null);

  useEffect(() => {
    if (error) {
      setBackendErorr(error);
    }
  }, [error]);
  return (
    <>
      <TextInput
        styles={{
          input: {
            borderColor: theme.colors.primary[1],
            borderWidth: '0.5px',
            borderStyle: 'solid',
            backgroundColor: 'transparent'
          }
        }}
        defaultValue={value}
        readOnly={read_only}
        withAsterisk={required}
        error={id in errors}
        classNames={{
          wrapper: styles.customTextInputWrapper,
          input: styles.customTextInputRoot,
          root: styles.wrapperRoot
        }}
        placeholder={'Введите значение'}
        label={label}
        {...register(id, {
          required: required,
          onChange: () => setBackendErorr(null)
        })}
      />
      <BackedErrorForBlock text={isBackendEror} />
    </>
  );
};
