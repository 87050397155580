import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import { useAppDispatch } from '../../hooks/redux';
import './project-categories.scss';
import { useAppSelector } from '../../hooks/redux';
import {
  setSearchProjectValue,
  setSelectedCategory
} from '../../store/slices/appStateSlice';
import { Category } from '../../types/state';
import { filterCategories } from '../../helpers/categories';
import ProjectCategoriesItem from '../project-categories-item/project-categories-item';
import { userId } from '../../helpers/api';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

const ProjectsCategories = () => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(
    (state) => state.projects.projects.categories
  );
  const projects = useAppSelector((state) => state.projects.projects.projects);

  const getCategories = (categories: Category[]) => {

    if (!userId) {
      return filterCategories(categories, projects);
    }
    return categories;
  };

  const visibleCategories = getCategories(categories);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useTelegramBackButton();

  const onCategoryClick = (name: string, id: number | null) => {
    dispatch(setSelectedCategory(name));
    dispatch(setSearchProjectValue(''));
  };

  if (!visibleCategories.length) {
    return <Container>Категории отсутствуют</Container>;
  }

  return (
    <div
      style={{
        position: 'relative',
        width: `${window.innerWidth}px`,
        overflow: 'hidden'
      }}>
      <ul className="categories-list">
        {visibleCategories.map((category) => (
          <ProjectCategoriesItem
            key={nanoid()}
            category={category}
            onCategoryClick={onCategoryClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default ProjectsCategories;
