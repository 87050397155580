import { Text } from '../../../../mantine';
import styles from './BackedErrorForBlock.module.scss';
import parse from 'html-react-parser';

interface BackedErrorForBlockProps {
  text: string | null;
}

export const BackedErrorForBlock = ({ text }: BackedErrorForBlockProps) => {
  return (
    <Text className={styles.backedErrorText}>{text ? parse(text) : null}</Text>
  );
};
