import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './about.scss';
import PageHeader from '../../../components/page-header/page-header';
import { NotFound } from '../../not-found/not-found';
import PageContent from '../../../components/page-content/page-content';
import { TG } from '../../../helpers/telegram';
import { useAppSelector } from '../../../hooks/redux';

const ProjectAbout = () => {
  const { currentProject } = useAppSelector((state) => state.projects);

  if (!currentProject) {
    return (
      <NotFound
        navigateTo={-1}
        alert="Не удалось получить подробную информацию о проекте"
        linkText="Назад к проекту"
      />
    );
  }

  const { name, logo, product_info } = currentProject.project;

  useEffect(() => {
    TG.MainButton?.hide();
    window.scroll(0, 0);
  }, []);

  return (
    <div className="project-about">
      <PageHeader
        title={'О продукте'}
        subtitle={name}
        icon={<img src={logo} alt={name} />}
      />
      <PageContent>
        <Container>
          {product_info && (
            <div className="mb-4">
              <div className="page__h2">{'Продукт'}</div>
              <p className="text-md">{product_info}</p>
            </div>
          )}
          {/* {adv_info && (
            <div className="mb-4">
              <div className="page__h2">{t('advertiser')}</div>
              <p className="text-md">{adv_info}</p>
            </div>
          )} */}
        </Container>
      </PageContent>
    </div>
  );
};

export default ProjectAbout;
