import { useState, useEffect, useRef, ChangeEvent, FocusEvent } from 'react';
import { TG } from '../helpers/telegram';

const useReferralModal = (
  showModal: boolean,
  setShowModal: (value: boolean) => void,
  shareText: string
) => {
  const [value, setValue] = useState<string>(shareText);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const closeReferralModalHandler = () => {
    setShowModal(false);
  };

  const handleCopyClick = () => {
    buttonRef.current?.click();
    TG.MainButton?.setText('Скопировано');
    TG.MainButton?.disable();
    setTimeout(() => {
      TG.MainButton?.setText('Копировать');
      TG.MainButton?.enable();
    }, 1500);
  };

  const onFocusReferralModalHandler = (e: FocusEvent<HTMLTextAreaElement>) => {
    e.currentTarget.setSelectionRange(
      e.currentTarget.value.length,
      e.currentTarget.value.length
    );
  };

  const onChangeReferralModalHandler = (
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (showModal) {
      TG.MainButton?.show();
      TG.MainButton?.setText('Копировать');
    }
    return () => {
      TG.MainButton?.hide();
    };
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      TG.MainButton?.onClick(handleCopyClick);
    }
    return () => {
      TG.MainButton?.offClick(handleCopyClick);
    };
  }, [value, showModal]);

  return {
    value,
    setValue,
    buttonRef,
    closeReferralModalHandler,
    onFocusReferralModalHandler,
    onChangeReferralModalHandler
  };
};

export default useReferralModal;
