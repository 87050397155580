import { Alert, Button, Container } from 'react-bootstrap';
import { TG } from '../../helpers/telegram';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../const';
import { useEffect } from 'react';
interface ErrorPageProps {
  alert?: string;
}

const ErrorPage = ({ alert }: ErrorPageProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    TG.MainButton?.hide();
  }, [navigate]);

  const closeBtnClickHandler = () => {
    navigate(PATHS.main);
  };

  return (
    <Container>
      <div className="error py-4">
        <Alert variant="danger">{alert ?? 'Произошла ошибка'}</Alert>
        <Button
          className="w-100"
          onClick={closeBtnClickHandler}
          color={'var(--tg-theme-button-color)'}>
          На главную
        </Button>
      </div>
    </Container>
  );
};

export default ErrorPage;
