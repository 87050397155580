import CustomModal from '../../../components/modal/modal';
import { Dispatch, SetStateAction } from 'react';
import { ModalTypes } from '../../../enums';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useReferralModal from '../../../hooks/useReferralModal';

import './referral-modal.scss';

interface ReferralModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  shareText: string;
}
const ReferralModal = ({
  showModal,
  setShowModal,
  shareText
}: ReferralModalProps) => {
  const {
    value,
    buttonRef,
    closeReferralModalHandler,
    onFocusReferralModalHandler,
    onChangeReferralModalHandler
  } = useReferralModal(showModal, setShowModal, shareText);

  return (
    <CustomModal
      show={showModal}
      hideHandler={closeReferralModalHandler}
      fullscreen={true}
      centered={false}
      type={ModalTypes.blind}>
      <div className="referral-modal">
        <textarea
          value={value}
          onChange={onChangeReferralModalHandler}
          onFocus={onFocusReferralModalHandler}
          autoFocus={true}
        />
        <CopyToClipboard text={value}>
          <button style={{ visibility: 'hidden' }} ref={buttonRef}></button>
        </CopyToClipboard>
      </div>
    </CustomModal>
  );
};

export default ReferralModal;
