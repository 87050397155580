import { ProjectsListType, ProjectsListItemType } from '../models/projects/projects';

export const filterProjectByCategory = (
  projects: ProjectsListType,
  category: string | null
) => {
  return projects
    .filter((project) => {
      return project.categories.some(
        (projCategories) =>
          projCategories.name.toLowerCase() === category?.toLocaleLowerCase()
      );
    })
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const filterProjectsWithOffers = (projects: ProjectsListType) => {
  return projects.filter((p) => Boolean(p.offers_count));
};


