import { Route, Routes } from 'react-router-dom';
import { PATHS } from '../const';
import MainMenu from '../pages/main-menu/main-menu';
import Projects from '../pages/projects/projects';
import ProjectsList from '../components/projects-list/projects-list';
import ProjectsCategories from '../components/project-categories/project-categories';
import Project from '../pages/project-detail/project';
import ProjectOffers from '../pages/project-detail/offers/offers';
import ProjectBonuses from '../pages/project-detail/bonuses/bonuses';
import ProjectAbout from '../pages/project-detail/about/about';
import ProjectForbidden from '../pages/project-detail/forbidden/forbidden';
import { NotFound } from '../pages/not-found/not-found';
import Referral from '../pages/referral/referral';
import Wallet from '../pages/wallet/wallet';
import Referrals from '../pages/referrals/referrals';
import Tips from '../pages/tips/tips';
import AddPaymentMethod from '../pages/add-payment-method/add-payment-method';
import ErrorPage from '../pages/error/error';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.main} element={<MainMenu />} />
      <Route path={PATHS.projects} element={<Projects />}>
        <Route index element={<ProjectsList />} />
        <Route path={PATHS.projects_list} element={<ProjectsList />} />
        <Route
          path={PATHS.projects_categories}
          element={<ProjectsCategories />}
        />
      </Route>
      <Route path={PATHS.project} element={<Project />}>
        <Route index element={<ProjectOffers />} />
        <Route path={PATHS.projectOffers} element={<ProjectOffers />} />
        <Route path={PATHS.projectBonuses} element={<ProjectBonuses />} />
        <Route path={PATHS.projectAbout} element={<ProjectAbout />} />
        <Route path={PATHS.projectForbidden} element={<ProjectForbidden />} />
        <Route path={PATHS.notFound} element={<NotFound />} />
      </Route>
      <Route path={PATHS.wallet} element={<Wallet />} />
      <Route path={PATHS.referral} element={<Referral />} />
      <Route path={PATHS.referrals} element={<Referrals />} />
      <Route path={PATHS.tips} element={<Tips />} />
      <Route path={'/add-wallet/:id'} element={<AddPaymentMethod />} />
      <Route path={PATHS.error} element={<ErrorPage />} />
      <Route path={PATHS.notFound} element={<NotFound />} />
    </Routes>
  );
};
