import { useFormContext } from 'react-hook-form';
import { Switch } from '../../../../mantine';

export interface CustomSwitchProps {
  type: 'switch';
  id: string;
  label: string;
  description: string;
  value: boolean;
  required: boolean;
}

export const CustomSwitch = ({
  description,
  id,
  label,
  required = false,
  type,
  value = false,
  ...props
}: CustomSwitchProps) => {
  const { register } = useFormContext();
  return (
    <Switch
      {...register(id, { required: required })}
      defaultChecked={value}
      color={'var(--tg-theme-button-color)'}
    />
  );
};
