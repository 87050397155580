import styles from './Calendar.module.scss';
import { DatePickerInput, Text } from '../../../../mantine';
import { Controller, useFormContext } from 'react-hook-form';
import { useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { BackedErrorForBlock } from '../BackendErrorForBlock';

export interface CalendarProps {
  type: 'date';
  id: string;
  label: string;
  value: string;
  required: boolean;
  format: string;
  error?: string;
}
const convertDate = (dateString: string) => {
  const parts = dateString.split('.');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  const dateObject = new Date(year, month, day, 0, 0, 0);

  return dateObject;
};
export const Calendar = ({
  error,
  format,
  id,
  label,
  required,
  type,
  value
}: CalendarProps) => {
  const theme = useMantineTheme();
  const [isBackendEror, setBackendErorr] = useState<null | string>(null);
  const { register, control } = useFormContext();

  useEffect(() => {
    if (error) {
      setBackendErorr(error);
    }
  }, [error]);

  return (
    <div className={styles.wrapper}>
      <Controller
        name={id}
        control={control}
        shouldUnregister={true}
        rules={{
          required: required
        }}
        defaultValue={value ? value : null}
        render={({ field: { ref, ...restField } }) => {
          return (
            <DatePickerInput
              {...restField}
              onChange={(event) => {
                setBackendErorr(null);
                restField.onChange(
                  dayjs(event?.toISOString()).format('DD.MM.YYYY')
                );
              }}
              value={restField.value ? convertDate(restField.value) : null}
              styles={{
                input: {
                  borderColor: theme.colors.primary[1],
                  borderWidth: '0.5px',
                  borderStyle: 'solid',
                  backgroundColor: 'transparent',
                  height: '44px'
                },
                root: {
                  width: '100%'
                }
              }}
              name={id}
              defaultValue={value ? convertDate(value) : null}
              label={label}
              placeholder="Выберите дату"
            />
          );
        }}
      />
      <BackedErrorForBlock text={isBackendEror} />
    </div>
  );
};
