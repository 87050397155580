import { api } from './api';
import {
  responseReferralsGeneralInfoSchema,
  responseReferralsListSchema
} from './models/referrals';
import { API_PATHS, BASE_URL } from '../const';

export const requestReferralsList = async () => {
  const response = await api.get(`${BASE_URL}${API_PATHS.referrals_list}`, {
    params: {}
  });

  return responseReferralsListSchema.parse(response.data);
};

export const requestReferralsGeneralInfo = async () => {
  const response = await api.get(`${BASE_URL}${API_PATHS.referrals_info}`, {
    params: {}
  });

  return responseReferralsGeneralInfoSchema.parse(response.data);
};
