import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CustomIcon from '../icons/custom';
import { CustomIcons } from '../../enums';
import { TG } from '../../helpers/telegram';

interface Props {
  label?: string;
  text: string;
  successText?: string;
  className?: string;
  callback?: () => void;
}

const CopyButton = ({
  label,
  text,
  successText,
  className,
  callback = () => {
    ('');
  }
}: Props) => {
  const SHOW_SUCCESS_TEXT_TIMEOUT = 2000;
  const [hasCopied, setHasCopied] = useState<boolean>(false);

  const successCallback = () => {
    setHasCopied(true);
    TG.HapticFeedback?.notificationOccurred('success');
    setTimeout(() => {
      setHasCopied(false);
    }, SHOW_SUCCESS_TEXT_TIMEOUT);
  };

  return (
    <>
      <CopyToClipboard text={text} onCopy={successCallback}>
        <button
          className={className ?? 'btn-copy'}
          type="button"
          disabled={hasCopied}
          onClick={() => {
            callback();
          }}>
          {hasCopied &&
            (successText ?? (
              <FontAwesomeIcon
                icon={faCheck}
                size="xl"
                style={{ color: 'var(--tg-theme-link-color)' }}
              />
            ))}
          {!hasCopied ? (
            label ? (
              label
            ) : (
              <CustomIcon icon={CustomIcons.copy} color="var(--tg-theme-link-color)" />
            )
          ) : (
            ''
          )}
        </button>
      </CopyToClipboard>
    </>
  );
};

export default CopyButton;
