import { useEffect } from 'react';
import { PATHS } from '../const';
import { sendError } from '../api/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from './redux';
import { fetchProjectPromosInfo, fetchProjectsAndCategories } from '../store/thunk/ProjectsThunk';
import { apiKey, userId } from '../helpers/api';
import { AxiosError } from 'axios';
import { resolveLoggerInstance } from '../helpers/logger';
import { TG } from '../helpers/telegram';

export const useLoadProjectInfo = () => {
  const dispatch = useAppDispatch();
  const logger = resolveLoggerInstance('fetchProjectPromosInfo');

  const sendKeyMissingError = () => {
    sendError({
      url: window.location.href,
      chat_id: userId,
      message: 'Key is missing'
    });
  };

  const sendChatIdMissingError = () => {
    sendError({
      url: window.location.href,
      chat_id: userId,
      message: 'Chat id is NULL',
      additionalData: TG.WebApp?.initDataUnsafe
    });
  };

  const loadProjectInfo = async (id: number) => {
    try {
      const response = await dispatch(fetchProjectPromosInfo({ id })).unwrap();

      if (!userId) {
        sendChatIdMissingError();
      }

      // Redirect from '/project/:id' to '/project/:id/offers'
      // if (pathname === `/project/${id}`) {
      //   navigate(`/project/${id}/offers?key=${apiKey}`);
      // }
    } catch (e) {
      if (!apiKey) {
        sendKeyMissingError();
      }

      if (e instanceof AxiosError) {
        logger.error(e);
        const xRequestId = e.response?.headers['x-request-id'];
        sendError({
          url: e.config?.url,
          chat_id: userId,
          message: e.message,
          error_id: xRequestId,
          code: e.code,
          cause: e.cause?.message,
          status: e.status,
          request: JSON.stringify(e.request)
        });
      }
    }
  };

  // useEffect(() => {
  //   void loadProjectInfo();
  // }, []);

  return loadProjectInfo;
};
