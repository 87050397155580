import { getMonthAndDate } from '../../../../helpers';
import { Payment } from '../PaymentInfoByMonth/PaymentInfoByMonth';
import { Container } from 'react-bootstrap';

interface PaymentInfoItemProps {
  label: string;
  currency: string;
  date: string;
  sum: string;
}

export const PaymentInfoItem = ({
  label,
  currency,
  date,
  sum
}: PaymentInfoItemProps) => {
  // let value = payment.value.toLocaleString('ru');
  // value = payment.value % 1 === 0 ? `${value},00` : value;

  return (
    <li className="list-rows__item fs-17">
      <Container>
        <span>
          {getMonthAndDate(date)}
          {/* {payment.day}&nbsp;{label} */}
        </span>
        <span>
          {sum}&nbsp;{currency}
        </span>
      </Container>
      <div className="line"></div>
    </li>
  );
};
