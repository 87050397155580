import { Title } from '../../../../mantine';
import styles from './CustomTitle.module.scss';
export interface CustomTitleProps {
  type: 'title';
  id: string;
  label: string;
  align: string;
}

export const CustomTitle = ({ type, id, label, align }: CustomTitleProps) => {
  return (
    <Title mb={'15px'} className={styles.customTitle}>
      {label}
    </Title>
  );
};
