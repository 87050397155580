import { z } from 'zod';
import { referralsDataSchema } from '../../models/referrals/referrals-info';
import { referralListDataSchema } from '../../models/referrals/referrals';

export const responseReferralsListSchema = z.object({
  success: z.boolean(),
  data: referralListDataSchema
});

export const responseReferralsGeneralInfoSchema = z.object({
  success: z.boolean(),
  data: referralsDataSchema
});

export type ResponseReferralsListType = z.infer<typeof responseReferralsListSchema>;
export type ResponseReferralsGeneralInfoType = z.infer<typeof responseReferralsGeneralInfoSchema>;
