import React, { Fragment, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import PageTitle from '../../components/page-title/page-title';
import SquaredIcon from '../../components/icons/squared/squared-icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TG } from '../../helpers/telegram';
import { CustomIcons, IconSource, colorSchemes } from '../../enums';
import CustomCard from '../../components/card/card';
import Balance from '../../components/balance/balance';
import { PATHS, currencySigns, Currency } from '../../const';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import CustomIcon from '../../components/icons/custom';
import Loading from '../loading/loading';
import { nanoid } from 'nanoid';
import { isNotEmptyReferralsRules } from '../../helpers/referrals';
import ErrorPage from '../error/error';
import { useAppSelector } from '../../hooks/redux';
import { useLoadReferralsInfo } from '../../hooks/useLoadReferralsInfo';
import ReferralModal from './referral-modal/referral-modal';
import './referral.scss';
import { apiKey } from '../../helpers/api';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

const Referral = () => {
  const loadReferralsGeneralInfo = useLoadReferralsInfo();
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    referralsInfo,
    errorReferralInfo,
    errorIdReferralInfo,
    loadingReferrals
  } = useAppSelector((state) => state.referrals);

  useTelegramBackButton();

  useEffect(() => {
    loadReferralsGeneralInfo();
  }, []);

  if (loadingReferrals || !referralsInfo) {
    return <Loading />;
  }

  if (errorReferralInfo) {
    return (
      <ErrorPage
        alert={`server_error:\n${errorReferralInfo}\n\n${errorIdReferralInfo}: ${errorIdReferralInfo}`}
      />
    );
  }

  const { sum, currency, users_count, ref_until, rules, share_ref_text } =
    referralsInfo;

  const currencySign = currencySigns[currency as Currency];
  const showRules = isNotEmptyReferralsRules(rules);

  const referralCustomCardStyles = {
    customCardBg:
      TG.WebApp?.colorScheme === colorSchemes.light ? 'var(--biege)' : '',
    customCardTitleColor:
      TG.WebApp?.colorScheme === colorSchemes.dark ? 'var(--orange)' : '',
    customCardIconColor:
      TG.WebApp?.colorScheme === colorSchemes.dark ? 'var(--orange)' : ''
  };

  return (
    <Fragment>
      <div className="page referral">
        <PageTitle
          icon={{ name: 'bullhorn', source: IconSource.fa }}
          title="Пригласи друга"
        />
        <div className="page-content">
          <Container>
            <Balance
              value={sum}
              currency={currencySign}
              hint="Заработано"
              className="mb-4"
            />
            <CustomCard className="p-0 mb-3">
              <Link
                className="referral__link"
                to={`${PATHS.referrals}?key=${apiKey}`}>
                <div>
                  <CustomIcon
                    icon={CustomIcons.user_group_lg}
                    className="me-1"
                    color={'var(--tg-theme-text-color)'}
                  />
                  Рефералы
                </div>
                <span>{users_count}</span>
              </Link>
            </CustomCard>
            <div className="banner">
              <div className="banner__header">
                <div className="banner__title">Зарабатывай</div>
                <div className="banner__subtitle">
                  приглашая родных
                  <br />
                  и&nbsp;друзей
                </div>
              </div>
              <div className="banner__footer">
                <SquaredIcon
                  icon={{ name: CustomIcons.card, source: IconSource.custom }}
                />
                <div className="me-2 banner__footer-content">
                  <div className="banner__footer-title">Вознаграждение</div>
                  <div className="banner__footer-subtitle">
                    от дохода реферала <span>{ref_until}</span>
                  </div>
                </div>
                <div className="banner__footer-referral-reward">30%</div>
              </div>
            </div>

            <CustomCard>
              <div className="custom-card__header">
                <FontAwesomeIcon
                  className="custom-card__icon"
                  size="xl"
                  icon="hand-point-right"
                />
                <span className="custom-card__title">Как пригласить?</span>
              </div>
              <div className="custom-card__body">
                <p>
                  Отправь ссылку на приложение и код, который нужно использовать
                  во время регистрации.
                </p>
                <p>
                  Реферал получит +20% к заработку в течение 30 дней после
                  регистрации.
                </p>
                <button
                  className="btn-main btn-main--inviteFriend"
                  type="button"
                  onClick={() => setShowModal(true)}>
                  Пригласить
                </button>
              </div>
            </CustomCard>

            {showRules && (
              <CustomCard bgColor={referralCustomCardStyles.customCardBg}>
                <div className="custom-card__header">
                  <FontAwesomeIcon
                    className="custom-card__icon"
                    icon="circle-info"
                    size="xl"
                    style={{
                      color: referralCustomCardStyles.customCardIconColor
                    }}
                  />
                  <span
                    className="custom-card__title"
                    style={{
                      color: referralCustomCardStyles.customCardTitleColor
                    }}>
                    Правила
                  </span>
                </div>
                <div className="custom-card__body">
                  <p>{rules.header}</p>
                  <ul className="list-default">
                    {rules.list.map((item) => (
                      <li key={nanoid()} className="list-default__item">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </CustomCard>
            )}
          </Container>
        </div>
      </div>
      <ReferralModal
        showModal={showModal}
        setShowModal={setShowModal}
        shareText={share_ref_text}
      />
    </Fragment>
  );
};

export default Referral;
