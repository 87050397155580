import { MAIN_MENU_ITEMS } from '../../const';
import { nanoid } from 'nanoid';
import './main-menu.scss';
import MenuListItem from '../../components/menu-item/menu-list-item';
import Socials from '../../components/socials/socials';
import { TG } from '../../helpers/telegram';
import { useState } from 'react';
import Loading from '../loading/loading';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

const MainMenu = () => {
  useTelegramBackButton();
  return (
    <div className="main-menu">
      <nav className="navigation">
        <ul className="menu">
          {MAIN_MENU_ITEMS.map((item) => (
            <MenuListItem key={nanoid(4)} item={item} />
          ))}
        </ul>
      </nav>
      <div className="socials">
        <Socials />
      </div>
    </div>
  );
};

export default MainMenu;
