import React, { ReactNode } from 'react';
import './page-content.scss';

type Props = {
  children: ReactNode;
};

const PageContent = ({ children }: Props): JSX.Element => {
  return <div className="page-content">{children}</div>;
};

export default PageContent;
