import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseProjectsAndCategoriesType,
  ResponseProjectPromosInfoType,
  ReponseParticipateInProjectType
} from '../../api/models/projects';
import {
  requestProjectsAndCategories,
  requestProjectPromosInfo,
  participateInProject
} from '../../api/projects';
import { updatePromocodes } from '../slices/projectsSlice';

export const fetchProjectsAndCategories = createAsyncThunk<
  ResponseProjectsAndCategoriesType,
  '{}',
  { rejectValue: AxiosError }
>('projects/fetchProjectsAndCategories', async (_, { rejectWithValue }) => {
  try {
    return await requestProjectsAndCategories();
  } catch (e) {
    return rejectWithValue(e as AxiosError);
  }
});

export const fetchProjectPromosInfo = createAsyncThunk<
  ResponseProjectPromosInfoType,
  { id: number },
  { rejectValue: AxiosError }
>(
  'projects/fetchProjectPromosInfo',
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const result = await requestProjectPromosInfo(id);
      return result;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const fetchParticipateInProject = createAsyncThunk<
  ReponseParticipateInProjectType,
  { project_id: number; landing_id: number },
  { rejectValue: AxiosError }
>(
  'projects/fetchParticipateInProject',
  async ({ project_id, landing_id }, { dispatch, rejectWithValue }) => {
    try {
      const result = await participateInProject(project_id, landing_id);
      dispatch(updatePromocodes(result.data.landings));
      return result;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);