import { useEffect } from 'react';
import { PaymentMethodCard, PaymentmethodSkeleton } from '../../components';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getAvailablePaymentMethods } from '../../../../store/thunk/walletThunk';
import styles from './PaymentMethodCardsList.module.scss';
import { Text } from '../../../../mantine';

export const PaymentMethodCardsList = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(
    (state) => state.wallet.availablePaymentMethods?.data
  );
  const isLoading = useAppSelector(
    (state) => state.wallet.availablePaymentMethods.isLoading
  );
  const isError = useAppSelector(
    (state) => state.wallet.availablePaymentMethods.error
  );

  useEffect(() => {
    dispatch(getAvailablePaymentMethods()).unwrap();
  }, []);

  if (isError) {
    return (
      <Text ta={'center'} c={'#FF3B30'}>
        Произошла ошибка при загрузке Ваших платежных методах. Попробуйте
        перезагрузить бота.
      </Text>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.skeletonWrapper}>
        <PaymentmethodSkeleton count={1} />
      </div>
    );
  }

  if (!data)
    return <Text ta={'center'}>У Вас нет привязаных платежных методов!</Text>;

  return (
    <div className={styles.wrapper}>
      {data.data.bound.map((method) => (
        <PaymentMethodCard key={method.id} {...method} />
      ))}
    </div>
  );
};
