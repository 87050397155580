import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { config } from '../config';
import { apiKey, userId } from '../helpers/api';

declare module 'axios' {
  export interface AxiosRequestConfig {
    retry?: number;
    retryDelay?: number;
    isRetry?: boolean;
  }
}

// create an axios client with a base URL
export const api = axios.create({
  baseURL: config.apiHost
});

// function to handle a successful response after a retry
const handleRetrySuccess = (response: AxiosResponse): AxiosResponse => {
  const { config } = response;
  if (config.isRetry) {
    sendError({
      url: window.location.href,
      chat_id: userId,
      message: 'Successful response after retry'
    });
  }
  return response;
};

// function to handle errors and retry requests
const handleRequestError = async (err: AxiosError): Promise<any> => {
  const { config, message } = err;

  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  if (!(message.includes('timeout') || message.includes('Network Error'))) {
    return Promise.reject(err);
  }

  config.isRetry = true;
  config.retry -= 1;

  await delayRetryRequest(config);
  return await api(config);
};

// function to delay retrying a request
const delayRetryRequest = async (config: AxiosRequestConfig): Promise<void> => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      console.log('Retrying the request', config.url);
      sendError({
        url: window.location.href,
        chat_id: userId,
        message: 'Successful response after retry'
      });
      resolve();
    }, config.retryDelay || 1000);
  });
};

// function to handle and log errors that occur during requests
const handleRequestGlobalError = (error: AxiosError): Promise<any> => {
  console.error('Error in request:', error);
  return Promise.reject(error);
};

type SendErrorProps = {
  url: string | undefined;
  chat_id?: number | null | undefined;
  message: string;
  error_id?: string;
  code?: string;
  status?: number;
  cause?: string;
  request?: string;
  additionalData?: unknown;
};

//function to send info that some error happened but retry was successful
export const sendError = (sendErrorProps: SendErrorProps) => {
  console.log(sendErrorProps);
};

// add interceptors
api.interceptors.request.use((config) => {
  config.params = { ...config.params, key: apiKey };
  // FOR NGROK
  // (config.params = { ...config.params, uid: 1000064941 }),
  //   (config.headers = {
  //     ...config.headers, // Preserve other headers
  //     'ngrok-skip-browser-warning': '69420' // Add custom header
  //   });
  return config;
});
api.interceptors.response.use(handleRetrySuccess, handleRequestError);
api.interceptors.response.use(undefined, handleRequestGlobalError);
