import { useEffect } from 'react';
import { PATHS } from '../const';
import { sendError } from '../api/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from './redux';
import { fetchProjectsAndCategories } from '../store/thunk/ProjectsThunk';
import { apiKey, userId } from '../helpers/api';
import { AxiosError } from 'axios';
import { resolveLoggerInstance } from '../helpers/logger';
import { TG } from '../helpers/telegram';

export const useLoadProjects = () => {
  const dispatch = useAppDispatch();

  const logger = resolveLoggerInstance('fetchProjects');

  const sendKeyMissingError = () => {
    sendError({
      url: window.location.href,
      chat_id: userId,
      message: 'Key is missing'
    });
  };

  const sendChatIdMissingError = () => {
    sendError({
      url: window.location.href,
      chat_id: userId,
      message: 'Chat id is NULL',
      additionalData: TG.WebApp?.initDataUnsafe
    });
  };

  const loadProjects = async () => {
    try {
      const response = await dispatch(
        fetchProjectsAndCategories('{}')
      ).unwrap();

      if (!userId) {
        sendChatIdMissingError();
      }
    } catch (e) {
      if (!apiKey) {
        sendKeyMissingError();
      }
      if (e instanceof AxiosError) {
        logger.error(e);
        const xRequestId = e.response?.headers['x-request-id'];
        sendError({
          url: e.config?.url,
          chat_id: userId,
          message: e.message,
          error_id: xRequestId,
          code: e.code,
          cause: e.cause?.message,
          status: e.status,
          request: JSON.stringify(e.request)
        });
      }
    }
  };

  // useEffect(() => {
  //   void loadProjects();
  // }, []);
  return loadProjects;
};
