import { TG } from './telegram';

export const SCROLL = {
  getScrollY: () => window.scrollY,
  getScrollX: () => window.scrollX,
  savePosition: () => {
    localStorage.setItem('scrollPosition', SCROLL.getScrollY().toString());
  },
  savePositionAlternative: () => {
    try {
      window.scrollPosition = SCROLL.getScrollY();
    } catch (error) {
      TG.WebApp?.CloudStorage.setItem(
        'scrollPosition',
        SCROLL.getScrollY().toString()
      );
    }
  },
  restorePosition: () => {
    const scrollPosition = localStorage.getItem('scrollPosition');
    console.log('scrollPosition =>>', scrollPosition);
    if (scrollPosition) {
      window.scroll(0, parseInt(scrollPosition));
      localStorage.removeItem('scrollPosition');
    }
  },
  restorePositionAlternative: () => {
    try {
      const scrollPosition = window.scrollPosition;
      if (scrollPosition) {
        window.scroll(0, scrollPosition);
        window.scrollPosition = 0;
      }
    } catch (error) {
      TG.WebApp?.CloudStorage.getItem(
        'scrollPosition',
        (error, scrollPosition) => {
          if (error) {
            return;
          }

          if (scrollPosition) {
            window.scroll(0, parseInt(scrollPosition));
            TG.WebApp?.CloudStorage.removeItem('scrollPosition');
          }
        }
      );
    }
  }
};

export const saveScrollPosition = () => {
  try {
    SCROLL.savePosition();
  } catch (e) {
    SCROLL.savePositionAlternative();
  }
};

export const restoreScrollPosition = () => {
  try {
    SCROLL.restorePosition();
  } catch (e) {
    SCROLL.restorePositionAlternative();
  }
};
