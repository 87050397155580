import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  MantineThemeOverride,
  TextInput,
  NumberInput,
  Switch,
  Checkbox,
  Text,
  Title,
  FileInput,
  Divider,
  Loader,
  MantineColorsTuple,
  Menu,
  Input,
  Select,
  Skeleton
} from '@mantine/core';

export {
  Button,
  TextInput,
  NumberInput,
  Switch,
  Checkbox,
  Text,
  Title,
  FileInput,
  Divider,
  Loader,
  Menu,
  Input,
  Select,
  DatePickerInput,
  Skeleton
};

import buttonStyles from './styles/buttonStyles.module.scss';
import textStyles from './styles/textStyles.module.scss';
import { TG } from '../helpers/telegram';

export const components: MantineThemeOverride['components'] = {
  Button: Button.extend({
    classNames() {
      return {
        root: buttonStyles.root
      };
    }
  }),
  Text: Text.extend({
    classNames() {
      return {
        root: textStyles.textStyles
      };
    }
  })
};

const lightPalette: Record<string, MantineColorsTuple> = {
  // не используются сейчас, просто так нужно, чтоб их было 10
  primary: [
    'black',
    '#C6C6C8', //border input
    '#90CAF9',
    '#64B5F6',
    '#42A5F5',
    '#2196F3',
    '#1E88E5',
    '#1976D2',
    '#1565C0',
    '#0D47A1'
  ],
  secondary: [
    '#F5F5F5',
    '#EEEEEE',
    '#E0E0E0',
    '#BDBDBD',
    '#9E9E9E',
    '#757575',
    '#616161',
    '#424242',
    '#212121',
    '#000000'
  ],
  background: [
    '#F8F9FA',
    '#F1F3F5',
    '#ECEFF1',
    '#E0E0E0',
    '#D6D6D6',
    '#CCCCCC',
    '#B3B3B3',
    '#A3A3A3',
    '#939393',
    '#828282'
  ],
  text: [
    '#212529',
    '#343A40',
    '#495057',
    '#6C757D',
    '#ADB5BD',
    '#CED4DA',
    '#DEE2E6',
    '#E9ECEF',
    '#F8F9FA',
    '#FFFFFF'
  ]
};

const darkPalette: Record<string, MantineColorsTuple> = {
  // не используются, просто так нужно, чтоб их было 10
  primary: [
    'white',
    '#38383A', //border input
    '#90CAF9',
    '#64B5F6',
    '#42A5F5',
    '#2196F3',
    '#1E88E5',
    '#1976D2',
    '#1565C0',
    '#0D47A1'
  ],
  secondary: [
    '#BDBDBD',
    '#9E9E9E',
    '#757575',
    '#616161',
    '#424242',
    '#212121',
    '#1B1B1B',
    '#121212',
    '#0D0D0D',
    '#000000'
  ],
  background: [
    '#121212',
    '#1D1D1D',
    '#242424',
    '#2B2B2B',
    '#333333',
    '#3A3A3A',
    '#414141',
    '#484848',
    '#505050',
    '#585858'
  ],
  text: [
    '#E0E0E0',
    '#D6D6D6',
    '#CCCCCC',
    '#B3B3B3',
    '#A3A3A3',
    '#939393',
    '#828282',
    '#727272',
    '#616161',
    '#515151'
  ]
};
const colorScheme = TG.WebApp?.colorScheme || 'light';

const lightTheme: MantineThemeOverride = {
  components,
  fontFamily: 'SF Pro Text',
  colors: {
    primary: lightPalette.primary as MantineColorsTuple,
    secondary: lightPalette.secondary as MantineColorsTuple,
    background: lightPalette.background as MantineColorsTuple,
    text: lightPalette.text as MantineColorsTuple
  },
  primaryColor: 'primary'
  // colorScheme: 'light'
};

const darkTheme: MantineThemeOverride = {
  components,
  fontFamily: 'SF Pro Text',
  colors: {
    primary: darkPalette.primary as MantineColorsTuple,
    secondary: darkPalette.secondary as MantineColorsTuple,
    background: darkPalette.background as MantineColorsTuple,
    text: darkPalette.text as MantineColorsTuple
  },
  primaryColor: 'primary'
  // colorScheme: 'dark'
};

export const THEME = colorScheme === 'dark' ? darkTheme : lightTheme;
