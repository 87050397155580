import { Text } from '../../../../mantine';
import styles from './CustomText.module.scss';

export interface CustomTextProps {
  type: 'text';
  id: string;
  text: string | undefined;
  is_black: boolean;
  size: number;
}
export const CustomText = ({
  type,
  id,
  text,
  is_black,
  size
}: CustomTextProps) => {
  return (
    <Text mb={'10px'} className={styles.customText}>
      {text}
    </Text>
  );
};
