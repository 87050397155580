import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaymentMethod } from '../../types/state';
import { PaymentMethodType } from '../../api/models/wallet';

export type paymentMethod = Pick<
  PaymentMethodType,
  'id' | 'name' | 'instruction_text' | 'pm_type_slug'
>;
interface AppState {
  selectedCategory: string | null;
  paymentMethod: paymentMethod | null;
  showProjectsNav: boolean;
  searchProjectValue: string;
}

const initialState: AppState = {
  selectedCategory: null,
  paymentMethod: null,
  showProjectsNav: true,
  searchProjectValue: ''
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setSelectedCategory(state, action: PayloadAction<string | null>) {
      state.selectedCategory = action.payload;
    },
    setPaymentMethod(state, action: PayloadAction<paymentMethod | null>) {
      state.paymentMethod = action.payload;
    },
    setShowProjectsNav(state, action: PayloadAction<boolean>) {
      state.showProjectsNav = action.payload;
    },
    setSearchProjectValue(state, action: PayloadAction<string>) {
      state.searchProjectValue = action.payload;
    }
  }
});

export const { setSelectedCategory, setPaymentMethod, setShowProjectsNav, setSearchProjectValue } =
  appStateSlice.actions;

export default appStateSlice.reducer;
