import { config } from '../config';
import { type NewPermissionName } from '../types/hepler-types';

export const getRandomArrayItem = (array: any[]) =>
  array[Math.floor(Math.random() * array.length)];

export const requestPermission = (permission: NewPermissionName) => {
  return navigator.permissions.query({ name: permission as PermissionName });
};

export const getQueryParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const isDev = () => {
  //for developing, delete later
  // return false;
  return config.dev;
};

export const isProd = () => {
  return config.prod;
};

export const getFirstSentence = (text: string) => {
  return text.split('. ')[0];
};

export const truncate = (
  str: string,
  n = 10,
  useWordBoundary = false
): string => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1); // the original check

  return `${
    useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString
  }...`;
};


export const calculateWindowWidth = () => {
  const width = document.documentElement.offsetWidth;
  return width > 0 ? `${width}px` : '100%';
};