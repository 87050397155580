import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { CustomIcons, IconSource } from '../../enums';
import CustomIcon from './custom';

interface Props {
  icon: IconName|CustomIcons,
  iconColor?: string,
  source: IconSource
}

const SocialIcon = ({ icon, iconColor, source }: Props): JSX.Element => {
  if (source === IconSource.custom) {
    const iconWithType = icon as CustomIcons;
    return <CustomIcon icon={iconWithType} color={iconColor} />;
  }
  if (source === IconSource.fa) {
    const iconWithType = icon as IconName;
    return <FontAwesomeIcon icon={['fab', iconWithType]} size="xl" style={{color: iconColor}} />;
  }

  return <></>;
};

export default SocialIcon;
