import { z } from 'zod';

export const rulesSchema = z.object({
  header: z.string(),
  list: z.array(z.string())
});

export const referralsDataSchema = z.object({
  sum: z.number(),
  currency: z.string(),
  users_count: z.number(),
  ref_until: z.string(),
  share_ref_text: z.string(),
  rules: rulesSchema
});

export type RulesType = z.infer<typeof rulesSchema>;
export type ReferralsDataType = z.infer<typeof referralsDataSchema>;
