import { useFormContext } from 'react-hook-form';
import { FileInput, Text } from '../../../../mantine';
import { CommonBlockTypes } from '../../componentsModel';
import { PlusIcon } from '../../icons';
import styles from './CustomFileCollection.module.scss';
import { useEffect, useState } from 'react';
import { uploadImages } from '../../../../api/wallet';
import { useDispatch } from 'react-redux';
import { uploadImage } from '../../../../store/thunk/walletThunk';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
export interface CustomFileCollectionProps extends CommonBlockTypes {
  type: 'file_collection';
  id: string;
  label: string;
  label_description: string;
  file_type: string;
  files: any;
  max_files: number;
  allow_edit: boolean;
}

export const CustomFileCollection = ({
  allow_edit,
  file_type,
  files,
  id,
  label,
  label_description,
  max_files,
  type,
  error
}: CustomFileCollectionProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  // const uploadedImageData = useAppSelector((state) => state.wallet.data);
  // const isLoading = useAppSelector((state) => state.wallet.isLoading);
  const dispatch = useAppDispatch();

  const handleFileChange = (newFiles: File[]) => {
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  useEffect(() => {
    if (uploadedFiles.length) {
      const formData = new FormData();
      formData.append('file', uploadedFiles[0]);
      formData.append('type', 'post_screen');
      dispatch(uploadImage({ formData })).unwrap();

      // uploadedFiles.forEach((file) => {

      // });

      // const res = uploadImages(formData);
      // console.log(res);
    }
  }, [uploadedFiles]);

  return (
    <div className={styles.fileCollectionWrapper}>
      <FileInput
        error={error}
        multiple={true}
        accept="image/png,image/jpeg"
        value={uploadedFiles}
        onChange={handleFileChange}
        leftSection={<PlusIcon width={20} height={30} />}
        label={label}
        leftSectionPointerEvents="none"
        description={label_description}
        classNames={{
          input: styles.fileInput,
          section: styles.fileInputSection,
          root: styles.inputWrapperRoot
        }}
        placeholder="Добавить"
      />
      <div className={styles.fileList}>
        {uploadedFiles?.map((file, index) => {
          const fileUrl = URL.createObjectURL(file);
          return (
            <div className={styles.uploadedItemWrapper} key={index}>
              <img
                className={styles.uploadedImg}
                src={fileUrl}
                alt={file.name}
                onLoad={() => URL.revokeObjectURL(fileUrl)}
              />
              <p key={index} style={{ margin: '0' }}>
                {file.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
// {
//     "type": "file_collection",
//     "id": "ip_scans",
//     "label": "Подтверждение УСН (скриншот из ЛК nalog.ru)",
//     "label_description": "Загрузи скриншот из ЛК ИП на nalog.ru, на котором видны ИНН и система налогообложения УСН. Эта информация есть в верхней части страницы или приложения.",
//     "file_type": "ip_scans",
//     "files": [],
//     "max_files": 10,
//     "allow_edit": true
// }
