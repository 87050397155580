import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faBriefcase, faMoneyCheck, faCheck, faBullhorn, faBinoculars, faHeadset, faCircleInfo, faClock, faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons';

const initFontAwesome = () => {
  library.add(
    faAngleRight,
    faBriefcase,
    faMoneyCheck,
    faCheck,
    faBullhorn,
    faBinoculars,
    faHeadset,
    faClock,
    faHandPointRight,
    faCircleInfo,
    faInstagram,
    faTelegram
  );
};

export default initFontAwesome;
