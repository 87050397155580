import styles from './SelectableCardList.module.scss';
import { PmCard } from '../PmCard';
import { PmCardBlock } from '../../componentsModel';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { selectedCardInSelectableCardList } from '../../../../store/slices/walletSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
export interface SelectableCardListProps {
  type: 'selectable_card_list';
  id: string;
  cards: PmCardBlock[];
}

export const SelectableCardList = ({
  cards,
  id,
  type
}: SelectableCardListProps) => {
  const dispatch = useAppDispatch();
  const selectedCardId = useAppSelector(
    (state) => state.wallet.selectedCard.id
  );

  const handleClick = (id: string) => {
    dispatch(selectedCardInSelectableCardList({ id }));
  };
  useEffect(() => {
    return () => {
      dispatch(selectedCardInSelectableCardList({ id: null }));
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      {cards.map(({ id, picture, subtitle, title, type }) => (
        <PmCard
          id={id}
          picture={picture}
          subtitle={subtitle}
          title={title}
          key={nanoid()}
          type={type}
          wrapperStyle={styles.pmCardWrapper}
          setSelected={handleClick}
          selectedId={selectedCardId}
        />
      ))}
    </div>
  );
};

//  {
//             "type": "selectable_card_list",
//             "id": "selectable_card_list",
//             "cards": [
//                 {
//                     "type": "pm_card",
//                     "id": "card",
//                     "title": "Карточка 1",
//                     "subtitle": "Подзаголовок карточки 1",
//                     "picture": "https://i.ytimg.com/vi/cxN08KRQoqI/maxresdefault.jpg"
//                 },
//                 {
//                     "type": "pm_card",
//                     "id": "card",
//                     "title": "Карточка 2",
//                     "subtitle": "Подзаголовок карточки 2",
//                     "picture": "https://i.ytimg.com/vi/cxN08KRQoqI/maxresdefault.jpg"
//                 }
//             ]
//         },
