import styles from './CopyableTextList.module.scss';
import { CopyButton, ActionIcon, Tooltip, rem } from '@mantine/core';
import { CopyIcon, FileIcon } from '../../icons';
import { Text } from '../../../../mantine';
import parse from 'html-react-parser';
import { useRef, useState } from 'react';
import { CheckMark } from '../../icons';

export interface CopyableTextListProps {
  type: 'copyable_text_list';
  id: string;
  texts: Record<string, string>;
  disabled: boolean;
  title: string;
}

const CopyableText = ({
  textForCopy,
  label
}: {
  textForCopy: string;
  label: string;
}) => {
  const [isCopied, setCopied] = useState<boolean>(false);
  const inputRef = useRef<any>(null);

  const copyText = () => {
    if (inputRef.current) {
      inputRef.current.select();
      inputRef.current.setSelectionRange(0, 99999); // Для мобильных устройств
      // Копируем текст
      document.execCommand('copy');
      // Сообщение об успешном копировании (опционально)
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };
  return (
    <div className={styles.copyableBlockWrapper}>
      <div className={styles.leftBlock}>
        <span
          className="copyable-text"
          onClick={() => {
            if (!isCopied) {
              copyText();
            }
          }}
          style={{
            cursor: 'pointer',
            display: 'inline-block'
          }}>
          {isCopied ? (
            <CheckMark width={24} height={24} />
          ) : (
            <CopyIcon width={24} height={24} />
          )}
        </span>
        <span>{label}</span>
      </div>
      <input
        type="text"
        ref={inputRef}
        value={textForCopy}
        readOnly
        style={{ backgroundColor: 'transparent', width: 'auto' }}
      />
    </div>
  );
};

export const CopyableTextList = ({
  disabled,
  id,
  texts,
  type,
  title
}: CopyableTextListProps) => {
  return (
    <>
      <Text>{title}</Text>
      <div className={styles.listWrapper}>
        {Object.entries(texts).map(([key, value], index) => {
          return <CopyableText textForCopy={value} label={key} key={index} />;
        })}
      </div>
    </>
  );
};
