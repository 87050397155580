import { PaymentHistoryType } from '../api/models/wallet';

export function localizeDate(date: string) {
  return new Date(date).toLocaleDateString();
}
export const getMonthAndYear = (date: string) => {
  const formated = new Intl.DateTimeFormat('ru', {
    month: 'long',
    year: 'numeric'
  }).format(new Date(date));

  return formated;
};

export const getMonthAndDate = (date: string) => {
  const formated = new Intl.DateTimeFormat('ru', {
    month: 'long',
    day: 'numeric'
  }).format(new Date(date));
  return formated;
};

export const sortDatesByMonthAndYear = (arrayOfDates: PaymentHistoryType[]) => {
  const ascSorted = arrayOfDates.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  const yearMonthMap = new Map<string, PaymentHistoryType[]>();

  ascSorted.forEach((item) => {
    const monthYearKey = getMonthAndYear(item.date);
    if (yearMonthMap.has(monthYearKey)) {
      const dates = yearMonthMap.get(monthYearKey)!;
      dates.push(item);
    } else {
      yearMonthMap.set(monthYearKey, [item]);
    }
  });

  return yearMonthMap;
};
