import { CustomIcons, IconSource } from './enums';
import { ReferralListDataType } from './models/referrals/referrals';
import { TG } from './helpers/telegram';
import {
  Socials,
  type Menu,
  type ProjectsAndCategoriesState
} from './types/state';

export const PATHS = {
  main: '/',
  projects: '/projects',
  projects_list: '/projects/list',
  projects_categories: '/projects/categories',
  wallet: '/wallet',
  addWallet: (id: string) => `/add-wallet/${id}`,
  referral: '/referral-info',
  referrals: '/referrals',
  tips: '/tips',
  project: '/project/:id',
  projectInfo: '/project/:id/info',
  projectOffers: '/project/:id/offers',
  projectAbout: '/project/:id/about',
  projectBonuses: '/project/:id/bonuses',
  projectForbidden: '/project/:id/forbidden',
  promoDetail: '/promo/:id',
  error: '/error',
  notFound: '*'
};

export const BASE_URL = '/bot-api';

export const API_PATHS = {
  project_promo_info: (id: number) => `/projects/${id}`,
  projects_and_categories: '/projects/list',
  projects_participate: '/projects/participate',
  referrals_list: '/referrals/my',
  referrals_info: '/referrals/info',
  available_payment_methods: '/wallet/pms-available',
  user_balance_details: '/wallet/balance',
  payment_history: '/wallet/payment-history',
  deletePaymentMethod: (id: number) => `/wallet/pms-delete/${id}`,
  setAsPrimary: (id: number) => `/wallet/pms-set-primary/${id}`,
  addPaymentMethods: '/wallet/pms-add-step',
  uploadImages: '/uploads/images',
  requestPayoutsMulti: '/wallet/payouts-request-multi'
};

export const TG_MAIN_BUTTON = {
  color: '#19D160',
  text_color: '#FFFFFF'
};

export const defaultReferralsState: ReferralListDataType = { referrals: [] };

export const SUPPORT_URL = 'https://t.me/mike1936';

export const MAIN_MENU_ITEMS: Menu = [
  {
    name: 'Проекты',
    desc: 'Доходы по проектам и аккаунтам',
    icon: {
      name: CustomIcons.case,
      source: IconSource.custom
    },
    path: PATHS.projects_list
  },
  {
    name: 'Кошелек',
    desc: 'Счета и вывод средств',
    icon: {
      name: CustomIcons.card,
      source: IconSource.custom
    },
    path: PATHS.wallet
  },
  {
    name: 'Реферальная программа',
    desc: 'Твои рефералы и доход с них',
    icon: {
      name: CustomIcons.horn,
      source: IconSource.custom
    },
    path: PATHS.referral
  },
  {
    name: 'Как экономить с Шерри',
    desc: 'Рекомендации и подсказки',
    icon: {
      name: CustomIcons.binoculars,
      source: IconSource.custom
    },
    path: PATHS.tips
  },
  {
    name: 'Поддержка',
    desc: 'Служба заботы',
    icon: {
      name: CustomIcons.chat,
      source: IconSource.custom
    },
    callback: () => {
      TG.WebApp?.openTelegramLink(SUPPORT_URL);
    }
  }
];

export const SOCIALS: Socials = [
  {
    name: 'Instagram',
    link: 'https://instagram.com/sharryapp',
    icon: {
      name: 'instagram',
      source: IconSource.fa
    }
  },
  {
    name: 'Telegram',
    link: 'https://t.me/sharryapp',
    icon: {
      name: 'telegram',
      source: IconSource.fa
    }
  }
];

export const TIPS = [
  {
    title: '01',
    image: 'cat-1',
    description:
      'Можешь покупать товары со скидкой самостоятельно, а еще лучше ➡️'
  },
  {
    title: '02',
    image: 'cat-2',
    description:
      'Сделай пост или сториз во ВКонтакте, ОК, Instagram, Tenchat или Whatsapp'
  },
  {
    title: '03',
    image: 'cat-3',
    description:
      'Опубликуй пост в группе ВК или ОК вашего города, чтобы охватить большую аудиторию'
  },
  {
    title: '04',
    image: 'cat-4',
    description:
      'Опубликуйся на форума мам, чате садика, школы. Мамы всегда ищут выгодные цены'
  },
  {
    title: '05',
    image: 'cat-5',
    description:
      'Отправь промокод друзьям и родственникам. Сообщи коллегам лично или в чат'
  },
  {
    title: '06',
    image: 'cat-6',
    description:
      'Сделай пост в группе совместных закупок, чтобы поделиться скидкой с друзьями'
  },
  {
    title: '07',
    image: 'cat-7',
    description:
      'Сделай публикацию в своём блоге на Дзен, в чате Discord или другой платформе'
  }
];

export const defaultTranslations = {
  project: 'Проект',
  projects: 'Проекты',
  categories: 'Категории',
  all_categories: 'Все категории',
  categories_empty: 'Категории отсутствуют',
  offers: 'Предложения',
  apply: 'Применить',
  send_to_tg: 'Отправить в Telegram',
  promo: 'Промокод',
  show_barcode_alert: 'Покажи штрих-код на кассе',
  redirect: 'Перейти на сайт',
  copy_and_redirect: 'Скопировать и перейти на сайт',
  before: 'до',
  page_not_found: 'Страница не найдена',
  to_index: 'На главную',
  copy_error: 'Ошибка копирования',
  copy_success: 'Текст скопирован',
  reload: 'Перезагрузить',
  server_error: 'Ошибка сервера. Попробуйте перезапустить приложение.',
  empty: 'Пусто',
  empty_key: 'Не указан ключ',
  empty_chat_id: 'Не указан ID чата',
  empty_request: 'По вашему запросу ничего не найдено',
  send_promo_success: 'Промокоды успешно отправлены',
  send_error: 'Ошибка отправки',
  link: 'Ссылка',
  link_for_subs: 'Ссылка для подписчиков',
  telegram_error:
    'Ошибка на стороне Telegram. Попробуйте перезапустить приложение.',
  close: 'Закрыть'
};

export const currencySigns = {
  RUB: '₽',
  USD: '$',
  EUR: '€',
  MXN: 'MXN $',
  GBP: '£',
  INR: '₹',
  UAH: '₴',
  BRL: 'R$',
  AED: 'AED',
  KZT: '₸',
  UZS: 'UZS',
  BYN: 'BYN',
  PKR: 'PKR',
  USDT: 'USDT'
} as const;

export type Currency = keyof typeof currencySigns;


