import { api } from './api';
import {
  responseParticipateInProjectSchema,
  responseProjectPromosInfoSchema,
  responseProjectsAndCategoriesSchema
} from './models/projects';
import { BASE_URL, API_PATHS } from '../const';

export const requestProjectsAndCategories = async () => {
  const response = await api.get(
    `${BASE_URL}${API_PATHS.projects_and_categories}`,
    {
      params: {}
    }
  );

  return responseProjectsAndCategoriesSchema.parse(response.data);
};

export const requestProjectPromosInfo = async (id: number) => {
  const response = await api.get(
    `${BASE_URL}${API_PATHS.project_promo_info(id)}`,
    {
      params: {}
    }
  );
  return responseProjectPromosInfoSchema.parse(response.data);
};

export const participateInProject = async (
  project_id: number,
  landing_id: number
) => {
  const response = await api.get(
    `${BASE_URL}${API_PATHS.projects_participate}`,
    {
      params: {
        project_id: project_id,
        landing_id: landing_id
      }
    }
  );
  return responseParticipateInProjectSchema.parse(response.data);
};
