import { useState, useEffect, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { apiKey } from '../helpers/api';
import { handleApiError, sendKeyMissingError } from '../helpers/errors';

type ApiResponse<T> = {
  data: T;
};

type ApiCallFunction<T> = () => Promise<ApiResponse<T>>;

interface UseFetchDataReturnType<T, E = string> {
  data: T | null;
  loading: boolean;
  error: E | null | string;
  refetch: () => void;
}

export function useFetchData<T, E = string>(
  apiCall: ApiCallFunction<T>
): UseFetchDataReturnType<T, E> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<E | null | string>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiCall();
      setData(response.data);
    } catch (err) {
      // уточнить
      !apiKey && sendKeyMissingError();
      if (err instanceof AxiosError) {
        const errorResponse = err as AxiosError<E>;
        setError(
          errorResponse.response?.data || 'An unexpected error occurred'
        );
        handleApiError(err);
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  }, [apiCall]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error, refetch: fetchData };
}
