import { CustomIcons } from '../../enums';
import { nanoid } from 'nanoid';
import WalletInfoItem from '../wallet-info-item/wallet-info-item';
import { UserBalanceDetailsType } from '../../api/models/wallet';

type WalletInfoProps = Pick<UserBalanceDetailsType, 'paid'>;

const WalletInfo = ({ paid }: WalletInfoProps) => {
  const walletInfo = [
    {
      icon: CustomIcons.clock,
      status: 'На проверке',
      value: 'value',
      border: false
    },
    {
      icon: CustomIcons.turtle,
      status: 'В процессе',
      value: 'value',
      border: true
    },
    {
      icon: CustomIcons.card,
      status: 'Выплачено',
      value: paid,
      border: true
    }
  ];
  return (
    <div className="row wallet-info">
      {walletInfo.map((walletInfo) => (
        <WalletInfoItem key={nanoid(4)} {...walletInfo} />
      ))}
    </div>
  );
};

export default WalletInfo;
