import React from 'react';
import './page-header.scss';
import { Container } from 'react-bootstrap';

type Props = {
  title: string;
  subtitle?: string;
  icon?: JSX.Element;
};

const PageHeader = ({ title, subtitle, icon }: Props): JSX.Element => {
  return (
    <>
      <Container>
        <div className="page-header">
          {icon && <div className="page-header__icon">{icon}</div>}
          <div className="flex-grow-1">
            <div className="page-header__title">{title}</div>
            {subtitle && <div className="page-header__subtitle">{subtitle}</div>}
          </div>
        </div>
      </Container>
    </>
  );
};

export default PageHeader;
