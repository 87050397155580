import './loading.scss';
import Loader from '../../components/loader/loader';

const Loading = () => {
  return (
    <div className="loading">
      <Loader />
    </div>
  );
};

export default Loading;
