import { TG } from '../../../helpers/telegram';
import { LinkType } from '../../../models/projects/promocodes';
import CopyButton from '../../buttons/copy-button';
import { Text } from '../../../mantine';
import './link-for-subs.scss';

interface Props {
  linkItem: LinkType;
}

const LinkForSubs = ({ linkItem }: Props) => {
  const { title, link } = linkItem;

  const redirectLinkClickHandler = () => {
    TG?.OpenLink?.(link);
  };

  return (
    <div className="link-for-subs__wrapper">
      <div className="link-for-subs__title">{title}</div>
      <div className="control contolWrapper">
        <div className="control__content linkControl">
          <Text className="control__text" truncate="end" maw={230}>
            {link}
          </Text>
        </div>
        <CopyButton text={link} className="control__button" />
      </div>
      <button
        className="btn-main"
        type="button"
        onClick={redirectLinkClickHandler}>
        Перейти на сайт
      </button>
    </div>
  );
};

export default LinkForSubs;
