import { z } from 'zod';

export const PaymentMethodActions = z.enum([
  'set-primary',
  'edit',
  'view',
  'delete'
]);

export const paymentMethod = z.object({
  pm_type_slug: z.string(),
  name: z.string(),
  icon: z.string(),
  description: z.string(),
  notice: z.string(),
  min_sum: z.string(),
  instruction_text: z.string(),
  tfa: z.boolean().nullable(),
  steps_count: z.number().nullable(),
  id: z.number().nullable(),
  status_right_type: z.string().nullable(),
  status_right: z.string().nullable(),
  status_left: z.string().nullable(),
  identifier: z.string().nullable(),
  actions: z.array(PaymentMethodActions).nullable(),
  has_acts: z.boolean().nullable(),
  date_from: z.string().nullable()
});

export const availablePaymentMethodsDataSchema = z.object({
  available: z.array(paymentMethod),
  bound: z.array(paymentMethod)
});

export const responseAvailablePaymentMethodsSchema = z.object({
  data: availablePaymentMethodsDataSchema,
  success: z.boolean()
});

export type PaymentMethodType = z.infer<typeof paymentMethod>;
export type ResponseAvailablePaymentMethodsType = z.infer<
  typeof responseAvailablePaymentMethodsSchema
>;
export type PaymentMethodAction = z.infer<typeof PaymentMethodActions>;

//

export const userBalanceDetails = z.object({
  balance: z.string(),
  solar_balance: z.string().nullable(),
  paid: z.string(),
  debt: z.string(),
  currency: z.string()
});

export const responseUserBalanceDetailsDataSchema = z.object({
  data: userBalanceDetails,
  success: z.boolean()
});

export type UserBalanceDetailsType = z.infer<typeof userBalanceDetails>;

//

export const payment = z.object({
  sum: z.string(),
  date: z.string(),
  currency: z.string()
});

export const responsePaymentHistorySchema = z.object({
  data: z.array(payment),
  success: z.boolean()
});

export type PaymentHistoryType = z.infer<typeof payment>;

//
export const responseDeletedPaymentMethodSchema = z.object({
  message: z.string(),
  success: z.boolean()
});

export type DeletedPaymentMethodData = z.infer<
  typeof responseDeletedPaymentMethodSchema
>;

// 
export const responseSetAsPrimaryPaymentMethodSchemz = z.object({
  message: z.string(),
  success: z.boolean()
});

export type SetAsPrimaryPaymentMethodData = z.infer<
  typeof responseSetAsPrimaryPaymentMethodSchemz
>;