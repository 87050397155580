import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type AdditionalData } from '../../types/api';
import { ProjectsAndCategoriesState } from '../../types/state';
import {
  fetchProjectPromosInfo,
  fetchProjectsAndCategories
} from '../thunk/ProjectsThunk';
import { ProjectPromosInfoDataType } from '../../api/models/projects';
import { ProjectsListType } from '../../models/projects/projects';

const initialProjectState = {
  projects: [],
  categories: []
};

interface ProjectsState {
  isLoadingProjects: boolean;
  isLoadingProject: boolean;
  error?: string;
  error_id?: string;
  errorProject?: string;
  errorIdProject?: string;
  projects: ProjectsAndCategoriesState;
  currentProject: null | ProjectPromosInfoDataType;
  additionalData: AdditionalData;
}

const initialState: ProjectsState = {
  isLoadingProjects: false,
  isLoadingProject: false,
  error: '',
  error_id: '',
  errorProject: '',
  errorIdProject: '',
  projects: initialProjectState,
  currentProject: null,
  additionalData: {
    user_id: 0,
    blogger_id: null
  }
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    updatePromocodes(state, action: PayloadAction<any>) {
      if (state.currentProject && state.currentProject.promocodes) {
        state.currentProject.promocodes.landings = action.payload;
        // state.currentProject.promocodes = action.payload;
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchProjectsAndCategories.pending, (state) => {
      state.isLoadingProjects = true;
      state.error = '';
    });
    builder.addCase(fetchProjectsAndCategories.fulfilled, (state, action) => {
      const { data } = action.payload;
      // const categories = Object.values(data.categories);
      const projects: ProjectsListType = data.projects;

      // const categoriesWithProjects = categories
      //   .map((category) => {
      //     let id = null;
      //     data.projects.find((project) => {
      //       if (project.category_name === category) {
      //         id = project.category_id;

      //         return true;
      //       }
      //     });

      //     return {
      //       id: id,
      //       name: category,
      //       projects_count: data.projects.filter(
      //         (project) => project.category_name === category
      //       ).length
      //     };
      //   })
      //   .filter((category) => category.name);

      state.projects = {
        projects: projects,
        categories: data.categories
      };
      state.error = '';
      state.isLoadingProjects = false;
    });
    builder.addCase(fetchProjectsAndCategories.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.error_id = action.payload?.response?.headers['x-request-id'];
      state.isLoadingProjects = false;
    });
    builder.addCase(fetchProjectPromosInfo.pending, (state) => {
      state.isLoadingProject = true;
      state.errorProject = '';
    });
    builder.addCase(fetchProjectPromosInfo.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.currentProject = data;
      state.isLoadingProject = false;
      state.errorProject = '';
    });
    builder.addCase(fetchProjectPromosInfo.rejected, (state, action) => {
      state.errorProject = action.payload?.message;
      state.errorIdProject = action.payload?.response?.headers['x-request-id'];
      state.isLoadingProject = false;
    });
  }
});
export const { updatePromocodes } = projectsSlice.actions;
export default projectsSlice.reducer;
