import { PATHS } from '../../const';
import { Link } from 'react-router-dom';
import { useBasePath } from '../../hooks/useBasePath';
import { useAppDispatch } from '../../hooks/redux';
import { setSelectedCategory } from '../../store/slices/appStateSlice';
import { Container } from 'react-bootstrap';
import { apiKey } from '../../helpers/api';
import { saveScrollPosition } from '../../helpers/scroll';

const ProjectsNav = (): JSX.Element => {
  const path = useBasePath();
  const dispatch = useAppDispatch();

  return (
    <div className="nav-buttons__wrapper">
      <Container>
        <nav className="nav-buttons">
          {path === PATHS.projects_list ? (
            <span className="nav-buttons__item nav-buttons__item--active">
              Проекты
            </span>
          ) : (
            <Link
              to={`${PATHS.projects_list}?key=${apiKey}`}
              className={'nav-buttons__item'}
              onClick={() => {
                dispatch(setSelectedCategory(null));
              }}>
              Проекты
            </Link>
          )}
          {path === PATHS.projects_categories ? (
            <span className="nav-buttons__item nav-buttons__item--active">
              Категории
            </span>
          ) : (
            <Link
              onClick={() => {
                saveScrollPosition();
              }}
              to={`${PATHS.projects_categories}?key=${apiKey}`}
              className={
                'nav-buttons__item ' +
                (path === PATHS.projects_categories
                  ? 'nav-buttons__item--active'
                  : '')
              }>
              Категории
            </Link>
          )}
        </nav>
      </Container>
    </div>
  );
};

export default ProjectsNav;
