import { useFormContext } from 'react-hook-form';
import { Checkbox } from '../../../../mantine';
import { CheckboxProps } from '@mantine/core';
import styles from './CustomCheckBox.module.scss';
import { BackedErrorForBlock } from '../BackendErrorForBlock';

export interface CustomCheckBoxProps extends CheckboxProps {
  type: 'checkbox';
  id: string;
  label: string;
  value: string;
  required: boolean;
}
export const CustomCheckBox = ({
  id,
  label,
  required,
  type,
  value,
  error
}: CustomCheckBoxProps) => {
  const { register } = useFormContext();
  // добавить error потом и сетить значение , возвращаемое с бека
  return (
    <div className={styles.wrapper}>
      <Checkbox color={'var(--tg-theme-button-color)'} {...register(id)} />
      <p className={styles.label}>{label}</p>
      {/* <BackedErrorForBlock text={error}/> */}
    </div>
  );
};
