import React from 'react';
import './balance.scss';
import CustomIcon from '../icons/custom';
import { CustomIcons } from '../../enums';

interface BalanceProps {
  value?: string | number;
  currency?: string | null;
  hint?: string;
  callback?: (() => void) | null;
  className?: string;
}

const Balance = ({
  value = 0,
  currency = null,
  hint,
  callback = null,
  className = ''
}: BalanceProps) => {
  return (
    <div className={`balance ${className}`}>
      <div className="d-flex align-items-center justify-content-center">
        <span className="balance__value">
          {value}&nbsp;{currency}
        </span>
        {callback && (
          <button className="balance__button" type="button" onClick={callback}>
            <CustomIcon icon={CustomIcons.download} color="var(--crimson)" />
          </button>
        )}
      </div>
      {hint && <div className="balance__hint mt-1">{hint}</div>}
    </div>
  );
};

export default Balance;
