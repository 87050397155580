import React from 'react';
import { SOCIALS } from '../../const';
import { nanoid } from 'nanoid';
import './socials.scss';
import SocialIcon from '../icons/social';
import { TG } from '../../helpers/telegram';
import { colorSchemes } from '../../enums';

const Socials = () => {
  return (
    <div className="socials">
      <div className="socials__title">Шерри в социальных сетях</div>
      <ul className="socials__list">
        {SOCIALS.map((social) => (
          <li className="socials-item" key={nanoid(4)}>
            <a
              className="socials-item__link"
              href={social.link}
              target="_blank"
              rel="noopener noreferrer">
              <SocialIcon
                icon={social.icon.name}
                source={social.icon.source}
                iconColor={
                  TG.WebApp?.colorScheme === colorSchemes.dark
                    ? '#FFFFFF'
                    : '#000000'
                }
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Socials;
