import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deletePaymentMethod,
  requestAvailablePaymentMethods,
  uploadImages,
  setAsPrimary,
  requestPayoutsMulti
} from '../../api/wallet';
import { AxiosError } from 'axios';
import {
  DeletedPaymentMethodData,
  ResponseAvailablePaymentMethodsType,
  SetAsPrimaryPaymentMethodData
} from '../../api/models/wallet';

export const uploadImage = createAsyncThunk<
  any,
  { formData: FormData },
  { rejectValue: AxiosError }
>('wallet/uploadImage', async ({ formData }, { rejectWithValue }) => {
  try {
    const result = await uploadImages(formData);
    return result;
  } catch (e) {
    return rejectWithValue(e as AxiosError);
  }
});

export const getAvailablePaymentMethods = createAsyncThunk<
  ResponseAvailablePaymentMethodsType,
  void,
  { rejectValue: AxiosError }
>('wallet/getAvailablePaymentMethods', async (_, { rejectWithValue }) => {
  try {
    const result = await requestAvailablePaymentMethods();
    return result;
  } catch (e) {
    return rejectWithValue(e as AxiosError);
  }
});

export const deletePaymentMethodFromWallet = createAsyncThunk<
  DeletedPaymentMethodData,
  { id: number },
  { rejectValue: AxiosError }
>(
  'wallet/deletePaymentMethodFromWallet',
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const result = await deletePaymentMethod(id);
      if (result.success) {
        dispatch(getAvailablePaymentMethods()).unwrap();
      }
      return result;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);

export const setAsPriamry = createAsyncThunk<
  SetAsPrimaryPaymentMethodData,
  { id: number },
  { rejectValue: AxiosError }
>('wallet/setAsPrimary', async ({ id }, { dispatch, rejectWithValue }) => {
  try {
    const result = await setAsPrimary(id);
    if (result.success) {
      dispatch(getAvailablePaymentMethods()).unwrap();
    }
    return result;
  } catch (e) {
    return rejectWithValue(e as AxiosError);
  }
});

export const requestPayouts = createAsyncThunk<{ rejectValue: AxiosError }>(
  'wallet/requestPayouts',
  async (_, { rejectWithValue }) => {
    try {
      const result = await requestPayoutsMulti();
      return result;
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);


