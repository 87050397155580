import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseReferralsGeneralInfoType,
} from '../../api/models/referrals';
import { requestReferralsGeneralInfo } from '../../api/referrals';

export const fetchReferralsGeneralInfo = createAsyncThunk<
  ResponseReferralsGeneralInfoType,
  '{}',
  { rejectValue: AxiosError }
>('projects/fetchReferralsGeneralInfo', async (_, { rejectWithValue }) => {
  try {
    return await requestReferralsGeneralInfo();
  } catch (e) {
    return rejectWithValue(e as AxiosError);
  }
});
