import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { fetchParticipateInProject } from '../thunk/ProjectsThunk';
import { ParticipateInProjectDataType } from '../../api/models/projects';

interface ParticipateInProjectState {
  isLoading: boolean;
  error: any;
  data: ParticipateInProjectDataType | null;
}
const initialState: ParticipateInProjectState = {
  isLoading: false,
  data: null,
  error: null
};

const participateInProjectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchParticipateInProject.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchParticipateInProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchParticipateInProject.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export default participateInProjectSlice.reducer;

export const selectIsLoading = (state: ParticipateInProjectState) =>
  state.isLoading;
export const selectData = (state: ParticipateInProjectState) => state.data;
export const selectError = (state: ParticipateInProjectState) => state.error;
