import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import './squared-icon.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomIcons, IconSource } from '../../../enums';
import CustomIcon from '../custom';
import { Icon } from '../../../types/state';

interface Props {
  icon: Icon;
  iconColor?: string;
  bgColor?: string;
  className?: string;
}

const SquaredIcon = ({
  icon,
  iconColor = '#ffffff',
  bgColor = '#000000',
  className = ''
}: Props) => {
  const { name, source } = icon;

  if (source === IconSource.custom) {
    const iconWithType = name as CustomIcons;
    return (
      <div className={`squared-icon ${className}`}>
        <CustomIcon icon={iconWithType} color={iconColor} />
      </div>
    );
  }
  if (source === IconSource.fa) {
    const iconWithType = name as IconName;
    return (
      <div className={`squared-icon ${className}`}>
        <FontAwesomeIcon
          icon={iconWithType}
          size="lg"
          style={{ color: iconColor }}
        />
      </div>
    );
  }

  return <></>;
};

export default SquaredIcon;
