import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PATHS } from '../../const';
import { setSearchProjectValue, setSelectedCategory } from '../../store/slices/appStateSlice';
import { Category } from '../../types/state';
import { useAppDispatch } from '../../hooks/redux';
import { apiKey } from '../../helpers/api';

interface ProjectCatoriesItemProps {
  category: Category;
  onCategoryClick: (name: string, id: number | null) => void;
}
const ProjectCategoriesItem = ({
  category,
  onCategoryClick
}: ProjectCatoriesItemProps) => {
  return (
    <li className="categories-list__item">
      <Container>
        <Link
          to={`${PATHS.projects_list}?key=${apiKey}`}
          className="categories-list__button"
          onClick={() => onCategoryClick(category.name, category.id)}>
          {category.name}
        </Link>
      </Container>
      <div className="line"></div>
    </li>
  );
};

export default ProjectCategoriesItem;
