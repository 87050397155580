import React, {FC} from 'react';
import {nanoid} from 'nanoid';
import CustomIcon from '../icons/custom';
import {CustomIcons} from '../../enums';

interface WalletInfoItemProps {
  icon: CustomIcons;
  status: string;
  value: string;
  border?: boolean;
}

const WalletInfoItem = ({
  border,
  icon,
  status,
  value
}: WalletInfoItemProps) => {
  return (
    <div className="col-4 wallet-info__item" key={nanoid(4)}>
      <div className="wallet-info__icon">
        <CustomIcon icon={icon} />
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="tg-text-hint" style={{ textAlign: 'center' }}>
          {status}
        </div>
        <div className="fw-600 text-center text-nowrap">{value}</div>
      </div>
    </div>
  );
};

export default WalletInfoItem;