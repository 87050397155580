import React from 'react';
import CSS from 'csstype';

interface Props {
  color?: string;
  className?: string;
  style?: CSS.Properties;
}

export const PresentIcon = ({ color = '#000000', className = '', style = {} }: Props) => {
  const colorCss = { color: color };
  const css = { ...style, ...colorCss };

  return (
    <>
      <svg
        className={className}
        style={css}
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.6875 20.8027C2.8418 20.8027 1.81641 19.8066 1.81641 17.9609V12.1113H1.75781C0.908203 11.877 0.458984 11.1445 0.458984 10.0898V7.60938C0.458984 6.29102 1.24023 5.4707 2.50977 5.4707H4.20898C3.68164 4.93359 3.37891 4.2207 3.37891 3.39062C3.37891 1.45703 4.92188 0.0605469 6.86523 0.0605469C8.30078 0.0605469 9.52148 0.871094 10 2.29688C10.4785 0.871094 11.6895 0.0605469 13.1348 0.0605469C15.0684 0.0605469 16.6211 1.45703 16.6211 3.39062C16.6211 4.2207 16.3086 4.93359 15.7812 5.4707H17.4902C18.8086 5.4707 19.541 6.29102 19.541 7.60938V10.0898C19.541 11.1445 19.0918 11.877 18.2422 12.1113H18.1836V17.9609C18.1836 19.8066 17.1484 20.8027 15.3027 20.8027H4.6875ZM9.07227 5.4707V4.17188C9.07227 2.79492 8.16406 1.89648 7.03125 1.89648C5.97656 1.89648 5.24414 2.53125 5.24414 3.57617C5.24414 4.64062 6.10352 5.4707 7.60742 5.4707H9.07227ZM10.918 5.4707H12.3926C13.8867 5.4707 14.7559 4.64062 14.7559 3.57617C14.7559 2.53125 14.0234 1.89648 12.9688 1.89648C11.8359 1.89648 10.918 2.79492 10.918 4.17188V5.4707ZM9.01367 10.5V7.19922H3.01758C2.54883 7.19922 2.35352 7.39453 2.35352 7.86328V9.83594C2.35352 10.3047 2.54883 10.5 3.01758 10.5H9.01367ZM10.9766 10.5H16.9922C17.4609 10.5 17.6465 10.3145 17.6465 9.85547V7.86328C17.6465 7.39453 17.4609 7.19922 16.9922 7.19922H10.9766V10.5ZM9.01367 19.0547V12.2285H3.71094V17.8535C3.71094 18.6152 4.14062 19.0547 4.91211 19.0547H9.01367ZM10.9766 19.0547H15.0781C15.8496 19.0547 16.2891 18.6152 16.2891 17.8535V12.2285H10.9766V19.0547Z"
          fill={color}
        />
      </svg>
    </>
  );
};
