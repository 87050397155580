import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getAvailablePaymentMethods,
  uploadImage,
  deletePaymentMethodFromWallet,
  setAsPriamry,
  requestPayouts
} from '../thunk/walletThunk';
import { ResponseAvailablePaymentMethodsType } from '../../api/models/wallet';
import { isStringObject } from 'util/types';

interface UploadImageState {
  data: any;
  isLoading: boolean;
  error: any;
}

interface AvailablePaymentMethodsState {
  data: ResponseAvailablePaymentMethodsType | null;
  isLoading: boolean;
  error: any;
}

interface DeletePaymethodState {
  data: any;
  isLoading: boolean;
  error: any;
}
interface SetAsPrimaryState {
  data: any;
  isLoading: boolean;
  error: any;
}
interface SelectedCardState {
  id: string | null;
}
interface RequestPayouts {
  data: any;
  isLoading: boolean;
  error: any;
}
interface initialState {
  uploadImage: UploadImageState;
  availablePaymentMethods: AvailablePaymentMethodsState;
  deletePaymentMethod: DeletePaymethodState;
  selectedCard: SelectedCardState;
  setAsPrimary: SetAsPrimaryState;
  requestPayouts: RequestPayouts;
}

const initialState: initialState = {
  uploadImage: {
    data: [],
    isLoading: false,
    error: null
  },
  availablePaymentMethods: {
    data: null,
    isLoading: false,
    error: null
  },
  deletePaymentMethod: {
    data: null,
    isLoading: false,
    error: null
  },
  setAsPrimary: {
    data: null,
    error: null,
    isLoading: false
  },
  requestPayouts: {
    data: null,
    isLoading: false,
    error: null
  },
  selectedCard: {
    id: null
  }
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    selectedCardInSelectableCardList(
      state,
      action: PayloadAction<{ id: string | null }>
    ) {
      state.selectedCard.id = action.payload.id;
    },
    cleanPayoutsRequestInfo(state) {
      state.requestPayouts.data = null;
      state.requestPayouts.error = null;
      state.requestPayouts.isLoading = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImage.pending, (state) => {
        state.uploadImage.isLoading = true;
        state.uploadImage.error = null;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.uploadImage.isLoading = false;
        state.uploadImage.data = [
          ...state.uploadImage.data,
          action.payload.data[0]
        ];
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.uploadImage.isLoading = false;
        state.uploadImage.error = action.payload;
      })
      .addCase(getAvailablePaymentMethods.pending, (state) => {
        state.availablePaymentMethods.isLoading = true;
        state.availablePaymentMethods.error = null;
      })
      .addCase(getAvailablePaymentMethods.fulfilled, (state, action) => {
        state.availablePaymentMethods.isLoading = false;
        state.availablePaymentMethods.data = action.payload;
      })
      .addCase(getAvailablePaymentMethods.rejected, (state, action) => {
        state.availablePaymentMethods.isLoading = false;
        state.availablePaymentMethods.error = action.payload;
      })

      .addCase(deletePaymentMethodFromWallet.pending, (state) => {
        state.deletePaymentMethod.isLoading = true;
        state.deletePaymentMethod.error = null;
      })
      .addCase(deletePaymentMethodFromWallet.fulfilled, (state, action) => {
        state.deletePaymentMethod.isLoading = false;
        state.deletePaymentMethod.data = action.payload;
      })
      .addCase(deletePaymentMethodFromWallet.rejected, (state, action) => {
        state.deletePaymentMethod.isLoading = false;
        state.deletePaymentMethod.error = action.payload;
      })
      .addCase(setAsPriamry.pending, (state) => {
        state.setAsPrimary.isLoading = true;
        state.setAsPrimary.error = null;
      })
      .addCase(setAsPriamry.fulfilled, (state, action) => {
        state.setAsPrimary.isLoading = false;
        state.setAsPrimary.data = action.payload;
      })
      .addCase(setAsPriamry.rejected, (state, action) => {
        state.setAsPrimary.isLoading = false;
        state.setAsPrimary.error = action.payload;
      })
      .addCase(requestPayouts.pending, (state) => {
        state.requestPayouts.isLoading = true;
        state.requestPayouts.error = null;
      })
      .addCase(requestPayouts.fulfilled, (state, action) => {
        state.requestPayouts.isLoading = false;
        state.requestPayouts.data = action.payload;
      })
      .addCase(requestPayouts.rejected, (state, action: PayloadAction<any>) => {
        state.requestPayouts.isLoading = false;
        state.requestPayouts.error = action.payload.response.data;
      });
  }
});
export const { selectedCardInSelectableCardList, cleanPayoutsRequestInfo } =
  walletSlice.actions;

export default walletSlice.reducer;
