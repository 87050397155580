import { nanoid } from 'nanoid';
import { PaymentInfoByMonth } from '../../components';
import { dataFromBack, paymentsInfoByMonth } from '../../data';
import { sortDatesByMonthAndYear } from '../../../../helpers';
import { PaymentHistoryType } from '../../../../api/models/wallet';

import styles from './PaymentInfoList.module.scss';

interface PaymentHistoryInfoListProps {
  paymentHistory: PaymentHistoryType[] | null;
}

export const PaymentInfoList = ({
  paymentHistory
}: PaymentHistoryInfoListProps) => {
  if (!paymentHistory?.length) {
    return <p className={styles.empyPaymentListText}>Выплаты отсутствуют</p>;
  }
  //dataFromBack -  для теста, так сюда paymentHistory
  const sortedDatesByMonthAndYear = sortDatesByMonthAndYear(paymentHistory);
  const entries = Array.from(sortedDatesByMonthAndYear.entries());

  return (
    <>
      {entries.map(([key, value], index) => {
        const monthTotalSum = value.reduce(
          (accumulator, currentValue) => accumulator + Number(currentValue.sum),
          0
        );
        return (
          <PaymentInfoByMonth
            date={key}
            payments={value}
            key={nanoid(4)}
            index={index}
            monthTotalSum={monthTotalSum}
          />
        );
      })}
    </>
  );
};
