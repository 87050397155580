import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import App from './app';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setupStore } from './store';
import { MantineProvider } from '@mantine/core';
import { THEME } from './mantine/index';
import { TG } from './helpers/telegram';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = setupStore();
const colorSheme = TG.WebApp?.colorScheme;


root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MantineProvider
        theme={THEME}
        defaultColorScheme={TG.WebApp?.colorScheme}>
        <App />
      </MantineProvider>
    </BrowserRouter>
  </Provider>
);
